
import { useState } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { useToast } from "./ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import confetti from "canvas-confetti";

export const NewsletterSignup = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const triggerConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      console.log('Attempting to subscribe email:', email);
      
      // Get browser information
      const userAgent = navigator.userAgent;
      const referrer = document.referrer;

      let ipData = { ip: null, country_name: null, city: null };
      try {
        // Get IP and location information using ipapi.co
        const ipResponse = await fetch('https://ipapi.co/json/');
        ipData = await ipResponse.json();
      } catch (ipError) {
        console.error('Failed to fetch IP data:', ipError);
        // Continue with subscription even if IP lookup fails
      }
      
      // First, save to subscribers table with additional information
      const { error: dbError } = await supabase
        .from('subscribers')
        .insert([{ 
          email,
          user_agent: userAgent,
          referrer: referrer || null,
          ip_address: ipData.ip,
          country: ipData.country_name,
          city: ipData.city
        }]);

      if (dbError) {
        // Check if it's a duplicate subscription
        if (dbError.code === '23505') {
          toast({
            title: "Already subscribed",
            description: "This email is already on our mailing list.",
            variant: "destructive",
          });
          return;
        }
        // For any other database error, throw it
        throw dbError;
      }

      // Database save was successful, show success message
      toast({
        title: "Thanks for subscribing!",
        description: "You'll receive updates in your inbox.",
      });
      
      triggerConfetti();
      setEmail("");

      // Try to send welcome email after showing success message
      try {
        const { error: functionError } = await supabase.functions.invoke('send-welcome-email', {
          body: { email }
        });
        
        if (functionError) {
          console.error('Failed to send welcome email:', functionError);
        }
      } catch (emailError) {
        // Just log the email error, user is already subscribed successfully
        console.error('Warning: Failed to send welcome email:', emailError);
      }
      
    } catch (error) {
      console.error('Error in subscription process:', error);
      toast({
        title: "Subscription failed",
        description: "Please try again later.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="py-12">
      <div className="max-w-[65ch] mx-auto px-6 md:px-8 space-y-4">
        <h2 className="text-lg font-normal">Receive my updates</h2>
        <p className="text-sm text-muted-foreground">
          Follow me via email,{" "}
          <a href="/rss" className="underline">RSS</a>,{" "}
          <a href="https://x.com/ScottTaylor" className="underline">𝕏</a>, and{" "}
          <span className="underline">smoke signals</span>
        </p>
        <form onSubmit={handleSubmit} className="flex gap-4 pt-2">
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="flex-grow py-1.5 px-0 border-0 border-b-2 border-gray-200 rounded-none focus-visible:ring-0 focus-visible:border-gray-400 transition-colors placeholder:text-gray-500 text-sm"
          />
          <Button 
            type="submit" 
            disabled={isLoading}
            className="bg-black text-white rounded-md px-4 py-1.5 hover:bg-gray-800 text-sm"
          >
            {isLoading ? "Signing up..." : "Sign up"}
          </Button>
        </form>
      </div>
    </section>
  );
};
