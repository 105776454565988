import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { format, subDays } from "date-fns";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { ChartContainer, ChartTooltipContent } from "@/components/ui/chart";

interface DailySignup {
  date: string;
  count: number;
}

export const SubscriberGraph = () => {
  const { data: signups = [], isLoading } = useQuery({
    queryKey: ['subscriber-signups'],
    queryFn: async () => {
      console.log('Fetching daily subscriber signups...');
      const startDate = subDays(new Date(), 29); // Last 30 days (including today)
      startDate.setHours(0, 0, 0, 0); // Start at beginning of the day
      
      const { data, error } = await supabase
        .from('subscribers')
        .select('created_at')
        .gte('created_at', startDate.toISOString());

      if (error) {
        console.error('Error fetching subscriber signups:', error);
        throw error;
      }

      // Group by date and count
      const dailyCounts = data.reduce((acc: { [key: string]: number }, item) => {
        const date = format(new Date(item.created_at), 'yyyy-MM-dd');
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      }, {});

      // Fill in missing dates with 0, starting from 29 days ago up to today
      const allDates: DailySignup[] = [];
      for (let i = 29; i >= 0; i--) {
        const date = format(subDays(new Date(), i), 'yyyy-MM-dd');
        allDates.push({
          date,
          count: dailyCounts[date] || 0
        });
      }

      console.log('Daily signups data:', allDates);
      return allDates;
    },
  });

  if (isLoading) {
    return <div className="h-[200px] w-full bg-muted animate-pulse rounded-lg" />;
  }

  return (
    <section className="mb-12">
      <h2 className="text-lg font-medium mb-4">Subscriber Growth</h2>
      <div className="h-[200px] w-full">
        <ChartContainer
          className="w-full h-full"
          config={{
            bar: {
              theme: {
                light: "hsl(var(--primary))",
                dark: "hsl(var(--primary))",
              },
            },
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={signups} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <XAxis
                dataKey="date"
                tickFormatter={(date) => format(new Date(date), 'MMM d')}
                stroke="hsl(var(--muted-foreground))"
                tick={{ fontSize: 12 }}
              />
              <YAxis 
                stroke="hsl(var(--muted-foreground))"
                tick={{ fontSize: 12 }}
                allowDecimals={false}
              />
              <CartesianGrid strokeDasharray="3 3" className="stroke-muted" />
              <Tooltip content={<ChartTooltipContent />} />
              <Bar
                dataKey="count"
                fill="hsl(var(--primary))"
                radius={[4, 4, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </ChartContainer>
      </div>
    </section>
  );
};