import { useState, useEffect } from "react";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";

interface XProfileInfoProps {
  url: string;
}

export const XProfileInfo = ({ url }: XProfileInfoProps) => {
  const [username, setUsername] = useState<string | null>(null);

  useEffect(() => {
    const extractUsername = (url: string) => {
      try {
        const urlObj = new URL(url);
        if (urlObj.hostname === 'x.com' || urlObj.hostname === 'twitter.com') {
          const pathParts = urlObj.pathname.split('/');
          // The username is typically the first part of the path after the /
          if (pathParts[1] && pathParts[1] !== '') {
            return pathParts[1];
          }
        }
        return null;
      } catch {
        return null;
      }
    };

    const username = extractUsername(url);
    setUsername(username);
  }, [url]);

  if (!username) return null;

  return (
    <div className="flex items-center gap-2">
      <Avatar className="h-6 w-6">
        <AvatarImage src={`https://unavatar.io/x/${username}`} alt={`@${username}`} />
        <AvatarFallback>
          {username.slice(0, 2).toUpperCase()}
        </AvatarFallback>
      </Avatar>
      <a 
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-sm text-muted-foreground hover:text-primary transition-colors"
      >
        @{username}
      </a>
    </div>
  );
};