import React, { useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate } from "react-router-dom";
import { EditorToolbar } from "./editor/EditorToolbar";
import { PublishControls } from "./editor/PublishControls";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { ImagePlus } from "lucide-react";

export const Editor = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [date, setDate] = useState<Date>(new Date());
  const [isDraft, setIsDraft] = useState(false);
  const [ogTitle, setOgTitle] = useState("");
  const [ogDescription, setOgDescription] = useState("");
  const [ogImage, setOgImage] = useState<File | null>(null);
  const [currentOgImageUrl, setCurrentOgImageUrl] = useState("");
  const { toast } = useToast();
  const navigate = useNavigate();
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  const handleFormat = (format: string, value?: string) => {
    if (!textareaRef.current) return;

    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = content.substring(start, end);

    let formattedText = '';
    switch (format) {
      case 'bold':
        formattedText = `**${selectedText}**`;
        break;
      case 'italic':
        formattedText = `*${selectedText}*`;
        break;
      case 'h1':
        formattedText = `# ${selectedText}`;
        break;
      case 'h2':
        formattedText = `## ${selectedText}`;
        break;
      case 'list':
        formattedText = selectedText.split('\n').map(line => `- ${line}`).join('\n');
        break;
      case 'image':
        formattedText = `![Image](${value})`;
        break;
      default:
        return;
    }

    const newContent = content.substring(0, start) + formattedText + content.substring(end);
    setContent(newContent);

    // Reset selection
    setTimeout(() => {
      textarea.focus();
      textarea.setSelectionRange(start, start + formattedText.length);
    }, 0);
  };

  const handlePublish = async () => {
    if (!title || !content) {
      toast({
        title: "Error",
        description: "Title and content are required.",
        variant: "destructive",
      });
      return;
    }

    try {
      console.log('Publishing article...');
      
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        toast({
          title: "Error",
          description: "You must be logged in to publish articles.",
          variant: "destructive",
        });
        navigate("/login");
        return;
      }

      let ogImageUrl = currentOgImageUrl;

      if (ogImage) {
        const fileExt = ogImage.name.split('.').pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const filePath = `${fileName}`;

        console.log('Uploading OG image:', filePath);

        const { error: uploadError, data: uploadData } = await supabase.storage
          .from('article-images')
          .upload(filePath, ogImage);

        if (uploadError) {
          console.error('Error uploading OG image:', uploadError);
          throw uploadError;
        }

        console.log('OG image uploaded successfully:', uploadData);

        const { data: { publicUrl } } = supabase.storage
          .from('article-images')
          .getPublicUrl(filePath);

        ogImageUrl = publicUrl;
      }

      const slug = title.toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)/g, '');

      console.log('Creating article with slug:', slug);

      const { data, error } = await supabase
        .from('articles')
        .insert([
          {
            title,
            content,
            slug,
            user_id: user.id,
            excerpt: content.slice(0, 150) + (content.length > 150 ? '...' : ''),
            created_at: date.toISOString(),
            draft: isDraft,
            og_title: ogTitle || title,
            og_description: ogDescription || content.slice(0, 200),
            og_image_url: ogImageUrl,
          }
        ])
        .select();

      if (error) {
        console.error('Error publishing article:', error);
        throw error;
      }

      console.log('Article published successfully:', data);
      
      toast({
        title: "Success",
        description: isDraft ? "Your draft has been saved." : "Your article has been published.",
      });
      
      navigate('/admin/posts');
    } catch (error) {
      console.error('Error in handlePublish:', error);
      toast({
        title: "Error",
        description: "Failed to publish article. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-4 max-w-4xl mx-auto">
      <Card className="p-4">
        <Input
          placeholder="Article Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="text-2xl font-bold mb-4"
        />
        <EditorToolbar onFormat={handleFormat} />
        <Textarea
          ref={textareaRef}
          placeholder="Write your article in markdown..."
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className="min-h-[400px] font-mono"
        />
        
        <div className="border-t mt-4 pt-4 space-y-4">
          <h3 className="text-lg font-semibold">Social Media Preview</h3>
          
          <div className="space-y-2">
            <Label htmlFor="ogTitle">Social Title (optional)</Label>
            <Input
              id="ogTitle"
              value={ogTitle}
              onChange={(e) => setOgTitle(e.target.value)}
              placeholder="Enter custom title for social media"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="ogDescription">Social Description (optional)</Label>
            <Input
              id="ogDescription"
              value={ogDescription}
              onChange={(e) => setOgDescription(e.target.value)}
              placeholder="Enter custom description for social media"
            />
          </div>

          <div className="space-y-2">
            <Label>Social Image</Label>
            <div className="flex items-center gap-4">
              <Input
                type="file"
                accept="image/*"
                onChange={(e) => setOgImage(e.target.files?.[0] || null)}
                className="hidden"
                id="ogImage"
              />
              <Button
                variant="outline"
                onClick={() => document.getElementById('ogImage')?.click()}
                className="flex items-center gap-2"
              >
                <ImagePlus className="h-4 w-4" />
                {ogImage ? 'Change Image' : 'Upload Image'}
              </Button>
              {currentOgImageUrl && (
                <img 
                  src={currentOgImageUrl} 
                  alt="Current OG image" 
                  className="h-10 w-10 object-cover rounded"
                />
              )}
            </div>
          </div>
        </div>

        <PublishControls
          date={date}
          setDate={setDate}
          isDraft={isDraft}
          setIsDraft={setIsDraft}
          onPublish={handlePublish}
        />
      </Card>
    </div>
  );
};
