
import { useEffect, useState } from "react";
import { useTheme } from "next-themes";
import { Button } from "@/components/ui/button";
import { Sun, Moon, LogOut } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useIsMobile } from "@/hooks/use-mobile";
import { supabase } from "@/integrations/supabase/client";

export const Header = () => {
  const [mounted, setMounted] = useState(false);
  const [user, setUser] = useState(null);
  const { resolvedTheme, setTheme } = useTheme();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  useEffect(() => {
    setMounted(true);
    
    // Check if user is logged in
    const getUser = async () => {
      const { data } = await supabase.auth.getUser();
      setUser(data.user);
    };
    
    getUser();
    
    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setUser(session?.user || null);
      }
    );

    return () => subscription.unsubscribe();
  }, []);

  const toggleTheme = () => {
    console.log('Toggling theme from:', resolvedTheme);
    setTheme(resolvedTheme === "dark" ? "light" : "dark");
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/login');
  };

  return (
    <header className="w-full">
      <div className="max-w-[65ch] mx-auto px-6 md:px-8 py-8 flex justify-between items-center">
        <div className="flex items-center gap-3 cursor-pointer group" onClick={() => navigate('/')}>
          <Avatar className="h-14 w-14">
            <AvatarImage src="/lovable-uploads/e21c023f-0e99-48f2-8d52-03579e92c5ba.png" alt="Scott Taylor" className="bg-card dark:bg-card" />
            <AvatarFallback className="bg-card dark:bg-card">ST</AvatarFallback>
          </Avatar>
          <h1 className={`text-2xl font-normal opacity-0 group-hover:opacity-100 transition-opacity duration-200 ${isMobile ? 'text-lg' : 'text-2xl'}`}>
            Scott Taylor
          </h1>
        </div>
        <nav className="flex items-center gap-4 md:gap-6">
          <a href="/about" className="text-muted-foreground hover:text-foreground text-sm md:text-base">About</a>
          <a href="/log" className="text-muted-foreground hover:text-foreground text-sm md:text-base">Log</a>
          <a href="/notes" className="text-muted-foreground hover:text-foreground text-sm md:text-base">Notes</a>
          
          {user && (
            <Button 
              variant="ghost" 
              size="icon"
              onClick={handleLogout}
              aria-label="Log out"
              title="Log out"
            >
              <LogOut className="h-5 w-5" />
            </Button>
          )}
          
          {mounted && (
            <Button 
              variant="ghost" 
              size="icon"
              onClick={toggleTheme}
              aria-label="Toggle theme"
            >
              {resolvedTheme === "dark" ? (
                <Sun className="h-5 w-5" />
              ) : (
                <Moon className="h-5 w-5" />
              )}
            </Button>
          )}
        </nav>
      </div>
    </header>
  );
};
