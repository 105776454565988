import { useQuery, useMutation } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Card } from "@/components/ui/card";
import { WysiwygEditor } from "../WysiwygEditor";

export const WelcomeEmailEditor = () => {
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const { toast } = useToast();

  const { data: template, isLoading } = useQuery({
    queryKey: ['welcome-email-template'],
    queryFn: async () => {
      console.log('Fetching welcome email template...');
      const { data, error } = await supabase
        .from('welcome_email_template')
        .select('*')
        .order('updated_at', { ascending: false })
        .limit(1)
        .maybeSingle();

      if (error) {
        console.error('Error fetching welcome email template:', error);
        throw error;
      }

      console.log('Welcome email template fetched:', data);
      return data;
    },
  });

  useEffect(() => {
    if (template) {
      console.log('Setting template data:', template);
      setSubject(template.subject || '');
      setContent(template.content || '');
    }
  }, [template]);

  const { mutate: updateTemplate, isPending } = useMutation({
    mutationFn: async () => {
      console.log('Updating welcome email template...');
      const { data: { user } } = await supabase.auth.getUser();
      
      const { error } = await supabase
        .from('welcome_email_template')
        .upsert({ 
          id: template?.id,
          subject, 
          content,
          updated_by: user?.id,
          updated_at: new Date().toISOString()
        });

      if (error) throw error;
    },
    onSuccess: () => {
      toast({
        title: "Success",
        description: "Welcome email template updated successfully.",
      });
    },
    onError: (error) => {
      console.error('Error updating welcome email template:', error);
      toast({
        title: "Error",
        description: "Failed to update welcome email template.",
        variant: "destructive",
      });
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Card className="p-4">
      <h2 className="text-xl font-medium mb-4">Welcome Email Template</h2>
      <div className="space-y-4">
        <Input
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <WysiwygEditor
          content={content}
          onChange={setContent}
          placeholder="Write your welcome email content..."
        />
        <Button 
          onClick={() => updateTemplate()}
          disabled={isPending || !subject || !content}
        >
          {isPending ? "Saving..." : "Save Template"}
        </Button>
      </div>
    </Card>
  );
};