import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";

const Unsubscribe = () => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const { toast } = useToast();
  const email = searchParams.get("email");

  useEffect(() => {
    const unsubscribe = async () => {
      if (!email) {
        toast({
          title: "Error",
          description: "No email provided",
          variant: "destructive",
        });
        setIsLoading(false);
        return;
      }

      try {
        console.log('Processing unsubscribe request for:', email);
        const { error } = await supabase.functions.invoke('unsubscribe', {
          body: { email }
        });

        if (error) throw error;

        setIsUnsubscribed(true);
        toast({
          title: "Unsubscribed",
          description: "You have been successfully unsubscribed from the newsletter.",
        });
      } catch (error) {
        console.error('Error unsubscribing:', error);
        toast({
          title: "Error",
          description: "Failed to unsubscribe. Please try again later.",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    };

    unsubscribe();
  }, [email, toast]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p>Processing your request...</p>
      </div>
    );
  }

  if (!email) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-destructive">Invalid unsubscribe link</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="max-w-md mx-auto px-6 py-12 text-center space-y-4">
        <h1 className="text-2xl font-semibold">
          {isUnsubscribed ? "You've been unsubscribed" : "Something went wrong"}
        </h1>
        <p className="text-muted-foreground">
          {isUnsubscribed 
            ? "You will no longer receive newsletter emails from us. You can always subscribe again if you change your mind."
            : "We couldn't process your unsubscribe request. Please try again later or contact support."}
        </p>
        <div className="pt-4">
          <a href="/" className="text-sm underline">
            Return to homepage
          </a>
        </div>
      </div>
    </div>
  );
};

export default Unsubscribe;