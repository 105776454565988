import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { format } from "date-fns";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Eye } from "lucide-react";

interface SentEmail {
  id: string;
  subject: string;
  content: string;
  sent_at: string | null;
}

export const SentEmailsList = ({ sentEmails }: { sentEmails: SentEmail[] | null }) => {
  const [selectedEmail, setSelectedEmail] = useState<SentEmail | null>(null);

  return (
    <div>
      <h2 className="text-xl font-medium mb-4">Sent Emails</h2>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Subject</TableHead>
            <TableHead>Preview</TableHead>
            <TableHead>Sent At</TableHead>
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sentEmails?.map((email) => (
            <TableRow key={email.id}>
              <TableCell>{email.subject}</TableCell>
              <TableCell className="max-w-md">
                <p className="truncate max-w-[300px]">
                  {email.content.replace(/<[^>]*>/g, '')}
                </p>
              </TableCell>
              <TableCell>
                {email.sent_at && format(new Date(email.sent_at), 'PPP')}
              </TableCell>
              <TableCell>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setSelectedEmail(email)}
                  className="flex items-center gap-2"
                >
                  <Eye className="h-4 w-4" />
                  View
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog open={!!selectedEmail} onOpenChange={() => setSelectedEmail(null)}>
        <DialogContent className="max-w-2xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>{selectedEmail?.subject}</DialogTitle>
          </DialogHeader>
          <div className="mt-4">
            <div 
              className="prose dark:prose-invert max-w-none"
              dangerouslySetInnerHTML={{ __html: selectedEmail?.content || '' }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};