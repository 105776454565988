import { format } from "date-fns";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Contribution, Habit } from "./types";

interface ContributionCellProps {
  contribution: Contribution;
  selectedHabit: string | null;
  totalHabits: number;
}

export const ContributionCell = ({ contribution, selectedHabit, totalHabits }: ContributionCellProps) => {
  const getContributionColor = (habits: Habit[], count: number) => {
    if (habits.length === 0) return 'rgb(235, 237, 240)';
    
    if (!selectedHabit) {
      // Calculate color based on completion percentage
      const percentage = count / totalHabits;
      if (percentage === 0) return 'rgb(235, 237, 240)';
      if (percentage <= 0.25) return '#9be9a8';
      if (percentage <= 0.5) return '#40c463';
      if (percentage <= 0.75) return '#30a14e';
      return '#216e39';
    }
    
    // For selected habit, use its color
    const selectedHabitData = habits.find(h => h.id === selectedHabit);
    return selectedHabitData ? selectedHabitData.color : 'rgb(235, 237, 240)';
  };

  const getHabitDescription = (contribution: Contribution) => {
    if (contribution.count === 0) return "No habits completed";
    const habitNames = contribution.habits.map(habit => habit.name).join(", ");
    return `Completed habits: ${habitNames}`;
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div
            className="w-3 h-3 rounded-sm transition-colors duration-200 hover:opacity-80 cursor-pointer"
            style={{
              background: getContributionColor(contribution.habits, contribution.count)
            }}
          />
        </TooltipTrigger>
        <TooltipContent className="flex flex-col gap-1">
          <p className="font-medium">
            {format(new Date(contribution.date), 'MMMM d, yyyy')}
          </p>
          <p className="text-sm text-muted-foreground">
            {getHabitDescription(contribution)}
          </p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};