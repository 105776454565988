import ReactMarkdown from "react-markdown";
import { Article } from "@/integrations/supabase/types/article";

interface ArticleContentProps {
  article: Article;
}

export const ArticleContent = ({ article }: ArticleContentProps) => {
  return (
    <article className="prose dark:prose-invert max-w-none">
      <h1 className="text-4xl font-normal mb-4">{article.title}</h1>
      <div className="text-muted-foreground mb-8">
        {new Date(article.created_at).toLocaleDateString('en-US', { 
          month: 'long', 
          day: 'numeric', 
          year: 'numeric'
        })}
      </div>
      <ReactMarkdown>{article.content}</ReactMarkdown>
    </article>
  );
};