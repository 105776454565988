import { useEffect } from 'react';
import { supabase } from "@/integrations/supabase/client";

const RssFeed = () => {
  useEffect(() => {
    const generateRssFeed = async () => {
      try {
        console.log('Fetching articles for RSS feed...');
        const { data: articles, error } = await supabase
          .from('articles')
          .select('*')
          .eq('draft', false)
          .order('created_at', { ascending: false });

        if (error) throw error;

        const feedContent = `<?xml version="1.0" encoding="UTF-8"?>
<?xml-stylesheet href="#feed-style" type="text/css"?>
<!DOCTYPE rss [<!ATTLIST xhtml:link xmlns:xhtml CDATA #FIXED "http://www.w3.org/1999/xhtml">]>
<rss version="2.0" xmlns:atom="http://www.w3.org/2005/Atom">
  <channel>
    <title>Scott Taylor's Blog</title>
    <link>https://${window.location.host}</link>
    <description>Scott Taylor's thoughts and writings</description>
    <language>en-us</language>
    <atom:link href="https://${window.location.host}/rss" rel="self" type="application/rss+xml" />
    <lastBuildDate>${new Date().toUTCString()}</lastBuildDate>
    ${articles.map(article => `
    <item>
      <title>${escapeXml(article.title)}</title>
      <link>https://${window.location.host}/${article.slug}</link>
      <guid isPermaLink="true">https://${window.location.host}/${article.slug}</guid>
      <pubDate>${new Date(article.created_at).toUTCString()}</pubDate>
      <description>${escapeXml(article.excerpt || '')}</description>
    </item>`).join('')}
  </channel>
  <style id="feed-style" type="text/css">
    <![CDATA[
      rss {
        display: block;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
        margin: 0;
        padding: 2rem;
        background: #f8f9fa;
        color: #212529;
        line-height: 1.6;
      }
      channel {
        display: block;
        max-width: 800px;
        margin: 0 auto;
        background: white;
        padding: 2rem;
        border-radius: 12px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.1);
      }
      channel > title {
        display: block;
        font-size: 2.5rem;
        font-weight: 700;
        margin: 0 0 1rem;
        color: #1a1a1a;
        border-bottom: 2px solid #e9ecef;
        padding-bottom: 1rem;
      }
      channel > description {
        display: block;
        font-size: 1.25rem;
        color: #6c757d;
        margin-bottom: 2rem;
      }
      link {
        display: block;
        color: #0066cc;
        text-decoration: none;
        margin: 0.5rem 0;
      }
      link:hover {
        text-decoration: underline;
      }
      link:before {
        content: "🔗 ";
        color: #6c757d;
      }
      item {
        display: block;
        margin: 2rem 0;
        padding: 1.5rem;
        background: #fff;
        border: 1px solid #e9ecef;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.05);
      }
      item:hover {
        border-color: #dee2e6;
        box-shadow: 0 2px 5px rgba(0,0,0,0.08);
      }
      item > title {
        display: block;
        font-size: 1.5rem;
        font-weight: 600;
        color: #1a1a1a;
        margin-bottom: 1rem;
        line-height: 1.3;
      }
      item > description {
        display: block;
        color: #495057;
        margin: 1rem 0;
        line-height: 1.6;
      }
      pubDate {
        display: block;
        color: #868e96;
        font-size: 0.875rem;
        margin-top: 1rem;
      }
      pubDate:before {
        content: "📅 ";
      }
      language, lastBuildDate, atom\\:link {
        display: none;
      }
    ]]>
  </style>
</rss>`;

        // Set the content type header
        document.querySelector('meta[http-equiv="Content-Type"]')?.remove();
        const meta = document.createElement('meta');
        meta.setAttribute('http-equiv', 'Content-Type');
        meta.setAttribute('content', 'application/xml; charset=utf-8');
        document.head.appendChild(meta);
        
        // Display the feed content
        document.documentElement.innerHTML = feedContent;
      } catch (error) {
        console.error('Error generating RSS feed:', error);
      }
    };

    generateRssFeed();
  }, []);

  return null;
};

// Helper function to escape XML special characters
const escapeXml = (unsafe: string) => {
  return unsafe.replace(/[<>&'"]/g, (c) => {
    switch (c) {
      case '<': return '&lt;';
      case '>': return '&gt;';
      case '&': return '&amp;';
      case '\'': return '&apos;';
      case '"': return '&quot;';
      default: return c;
    }
  });
};

export default RssFeed;