
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { supabase } from "@/integrations/supabase/client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { useTheme } from "next-themes";
import { Loader2 } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";

const LoginPage = () => {
  const navigate = useNavigate();
  const { resolvedTheme } = useTheme();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Check if user is already logged in
    const checkUser = async () => {
      try {
        setLoading(true);
        const { data: { user }, error: userError } = await supabase.auth.getUser();
        
        if (userError) {
          console.error("Error checking user:", userError);
          // Just continue to the login page if there's an error
        } else if (user) {
          // User is logged in, redirect to admin dashboard
          navigate("/admin");
        }
      } catch (err) {
        console.error("Exception checking user:", err);
      } finally {
        setLoading(false);
      }
    };

    checkUser();

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      (event, session) => {
        console.log("Auth event:", event);
        if (event === 'SIGNED_IN' && session) {
          navigate("/admin");
        } else if (event === 'USER_UPDATED' && session) {
          navigate("/admin");
        } else if (event === 'SIGNED_OUT') {
          // Do nothing, we're already on the login page
        }
      }
    );

    return () => subscription.unsubscribe();
  }, [navigate]);

  // Handle auth errors from URL query parameters
  useEffect(() => {
    const url = new URL(window.location.href);
    const errorDescription = url.searchParams.get('error_description');
    if (errorDescription) {
      setError(decodeURIComponent(errorDescription));
    }
  }, []);

  // Log to help debug redirect issues
  useEffect(() => {
    console.log("Current origin:", window.location.origin);
    console.log("Redirect URL:", `${window.location.origin}/admin`);
  }, []);

  return (
    <div className="min-h-screen bg-background flex items-center justify-center p-4">
      <Card className="w-full max-w-md p-8">
        <h1 className="text-2xl font-bold mb-4 text-center text-foreground">Welcome</h1>
        <p className="text-center text-muted-foreground mb-8">Sign in to access your account</p>
        
        {error && (
          <Alert variant="destructive" className="mb-6">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {loading ? (
          <div className="flex justify-center items-center py-8">
            <Loader2 className="h-8 w-8 animate-spin text-primary" />
          </div>
        ) : (
          <Auth
            supabaseClient={supabase}
            appearance={{
              theme: ThemeSupa,
              variables: {
                default: {
                  colors: {
                    brand: 'hsl(var(--primary))',
                    brandAccent: 'hsl(var(--primary))',
                    inputBackground: 'hsl(var(--background))',
                    inputText: 'hsl(var(--foreground))',
                    inputPlaceholder: 'hsl(var(--muted-foreground))',
                    defaultButtonBackground: 'hsl(var(--secondary))',
                    defaultButtonBackgroundHover: 'hsl(var(--secondary))',
                    defaultButtonText: 'hsl(var(--foreground))',
                    dividerBackground: 'hsl(var(--border))',
                  }
                },
                dark: {
                  colors: {
                    brand: 'hsl(var(--primary))',
                    brandAccent: 'hsl(var(--primary))',
                    inputBackground: 'hsl(var(--background))',
                    inputText: 'hsl(var(--foreground))',
                    inputPlaceholder: 'hsl(var(--muted-foreground))',
                    defaultButtonBackground: 'hsl(var(--secondary))',
                    defaultButtonBackgroundHover: 'hsl(var(--secondary))',
                    defaultButtonText: 'hsl(var(--foreground))',
                    dividerBackground: 'hsl(var(--border))',
                  }
                }
              },
              className: {
                container: 'text-foreground',
                button: 'bg-primary text-primary-foreground hover:bg-primary/90',
                input: 'bg-background border-input',
                label: 'text-foreground',
                loader: 'text-foreground',
                message: 'text-foreground',
                anchor: 'text-primary hover:text-primary/80',
              }
            }}
            theme={resolvedTheme}
            providers={['google']}
            redirectTo={`${window.location.origin}/admin`}
            onlyThirdPartyProviders={true}
          />
        )}
        <p className="text-center text-sm text-muted-foreground mt-6">
          By signing in, you agree to our Terms of Service and Privacy Policy
        </p>
      </Card>
    </div>
  );
};

export default LoginPage;
