import { useState, useEffect } from "react";
import { useToast } from "@/components/ui/use-toast";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { ImagePlus, Loader2 } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";

const SiteSettingsAdmin = () => {
  const [ogTitle, setOgTitle] = useState("");
  const [ogDescription, setOgDescription] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [ogImage, setOgImage] = useState<File | null>(null);
  const [favicon, setFavicon] = useState<File | null>(null);
  const [currentOgImageUrl, setCurrentOgImageUrl] = useState("");
  const [currentFaviconUrl, setCurrentFaviconUrl] = useState("");
  const { toast } = useToast();

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    try {
      console.log('Loading site settings...');
      const { data, error } = await supabase
        .from('site_settings')
        .select('*')
        .maybeSingle();

      if (error) throw error;

      if (data) {
        console.log('Site settings loaded:', data);
        setOgTitle(data.og_title || '');
        setOgDescription(data.og_description || '');
        setCurrentOgImageUrl(data.og_image_url || '');
        setCurrentFaviconUrl(data.favicon_url || '');
      }
    } catch (error) {
      console.error('Error loading site settings:', error);
      toast({
        title: "Error",
        description: "Failed to load site settings",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const uploadImage = async (file: File, bucket: string) => {
    const fileExt = file.name.split('.').pop();
    const fileName = `${Math.random()}.${fileExt}`;
    const filePath = `${fileName}`;

    const { data, error } = await supabase.storage
      .from(bucket)
      .upload(filePath, file);

    if (error) throw error;

    const { data: { publicUrl } } = supabase.storage
      .from(bucket)
      .getPublicUrl(filePath);

    return publicUrl;
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      console.log('Saving site settings...');

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      let ogImageUrl = currentOgImageUrl;
      let faviconUrl = currentFaviconUrl;

      if (ogImage) {
        ogImageUrl = await uploadImage(ogImage, 'article-images');
      }

      if (favicon) {
        faviconUrl = await uploadImage(favicon, 'article-images');
      }

      const { error } = await supabase
        .from('site_settings')
        .upsert({
          og_title: ogTitle,
          og_description: ogDescription,
          og_image_url: ogImageUrl,
          favicon_url: faviconUrl,
          updated_by: user.id,
        });

      if (error) throw error;

      toast({
        title: "Success",
        description: "Site settings saved successfully",
      });

      // Reload settings to show updated values
      await loadSettings();
    } catch (error) {
      console.error('Error saving site settings:', error);
      toast({
        title: "Error",
        description: "Failed to save site settings",
        variant: "destructive",
      });
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-semibold">Site Settings</h1>
      <Card className="p-6 space-y-6">
        <div className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="ogTitle">Default OG Title</Label>
            <Input
              id="ogTitle"
              value={ogTitle}
              onChange={(e) => setOgTitle(e.target.value)}
              placeholder="Enter default OG title"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="ogDescription">Default OG Description</Label>
            <Input
              id="ogDescription"
              value={ogDescription}
              onChange={(e) => setOgDescription(e.target.value)}
              placeholder="Enter default OG description"
            />
          </div>

          <div className="space-y-2">
            <Label>Default OG Image</Label>
            <div className="flex items-center gap-4">
              <Input
                type="file"
                accept="image/*"
                onChange={(e) => setOgImage(e.target.files?.[0] || null)}
                className="hidden"
                id="ogImage"
              />
              <Button
                variant="outline"
                onClick={() => document.getElementById('ogImage')?.click()}
                className="flex items-center gap-2"
              >
                <ImagePlus className="h-4 w-4" />
                {ogImage ? 'Change Image' : 'Upload Image'}
              </Button>
              {currentOgImageUrl && (
                <img 
                  src={currentOgImageUrl} 
                  alt="Current OG image" 
                  className="h-10 w-10 object-cover rounded"
                />
              )}
            </div>
          </div>

          <div className="space-y-2">
            <Label>Favicon</Label>
            <div className="flex items-center gap-4">
              <Input
                type="file"
                accept="image/*"
                onChange={(e) => setFavicon(e.target.files?.[0] || null)}
                className="hidden"
                id="favicon"
              />
              <Button
                variant="outline"
                onClick={() => document.getElementById('favicon')?.click()}
                className="flex items-center gap-2"
              >
                <ImagePlus className="h-4 w-4" />
                {favicon ? 'Change Image' : 'Upload Image'}
              </Button>
              {currentFaviconUrl && (
                <img 
                  src={currentFaviconUrl} 
                  alt="Current favicon" 
                  className="h-10 w-10 object-cover rounded"
                />
              )}
            </div>
          </div>
        </div>

        <Button 
          onClick={handleSave} 
          disabled={isSaving}
          className="flex items-center gap-2"
        >
          {isSaving && <Loader2 className="h-4 w-4 animate-spin" />}
          {isSaving ? 'Saving...' : 'Save Settings'}
        </Button>
      </Card>
    </div>
  );
};

export default SiteSettingsAdmin;