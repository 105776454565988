import ReactMarkdown from "react-markdown";

interface ArticleCardProps {
  title: string;
  excerpt: string;
  date: string;
  slug: string;
}

export const ArticleCard = ({ title, excerpt, date, slug }: ArticleCardProps) => {
  return (
    <article 
      className="space-y-2 animate-fade-in cursor-pointer" 
      onClick={() => window.location.href = `/${slug}`}
    >
      <div className="flex items-center text-muted-foreground text-sm">
        {new Date(date).toLocaleDateString('en-US', { 
          month: 'long', 
          day: 'numeric', 
          year: 'numeric'
        })} · 1 minute read
      </div>
      <h2 className="text-2xl font-normal">{title}</h2>
      <div className="text-muted-foreground line-clamp-1">
        <ReactMarkdown>{excerpt}</ReactMarkdown>
      </div>
      <div>
        <a href={`/${slug}`} className="text-foreground hover:underline">
          Keep reading →
        </a>
      </div>
    </article>
  );
};