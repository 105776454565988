import React from "react";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { cn } from "@/lib/utils";

interface PublishControlsProps {
  date: Date;
  setDate: (date: Date) => void;
  isDraft: boolean;
  setIsDraft: (isDraft: boolean) => void;
  onPublish: () => void;
}

export const PublishControls = ({
  date,
  setDate,
  isDraft,
  setIsDraft,
  onPublish,
}: PublishControlsProps) => {
  return (
    <div className="flex items-center gap-4 mt-4">
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={cn(
              "w-[240px] justify-start text-left font-normal",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? format(date, "PPP") : <span>Pick a date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="single"
            selected={date}
            onSelect={(newDate) => newDate && setDate(newDate)}
            initialFocus
          />
        </PopoverContent>
      </Popover>
      <div className="flex items-center space-x-2">
        <Switch
          id="draft-mode"
          checked={isDraft}
          onCheckedChange={setIsDraft}
        />
        <Label htmlFor="draft-mode">Save as draft</Label>
      </div>
      <Button onClick={onPublish}>
        {isDraft ? "Save Draft" : "Publish"}
      </Button>
    </div>
  );
};