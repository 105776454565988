import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { HabitLegend } from "./habits/HabitLegend";
import { ContributionCell } from "./habits/ContributionCell";
import { Habit, Contribution } from "./habits/types";

export const ContributionGraph = () => {
  const [selectedHabit, setSelectedHabit] = useState<string | null>(null);
  const [allHabits, setAllHabits] = useState<Habit[]>([]);

  const { data: contributions = [], isLoading } = useQuery({
    queryKey: ['habit-entries'],
    queryFn: async () => {
      console.log('Fetching habit entries...');
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 364); // Last 365 days
      
      // First, fetch all habits
      const { data: habits, error: habitsError } = await supabase
        .from('habits')
        .select('id, name, color');
      
      if (habitsError) {
        console.error('Error fetching habits:', habitsError);
        throw habitsError;
      }

      console.log('All habits:', habits);
      setAllHabits(habits);
      
      // Then fetch the entries
      const { data: entries, error: entriesError } = await supabase
        .from('habit_entries')
        .select(`
          date,
          habits (
            id,
            name,
            color
          )
        `)
        .gte('date', startDate.toISOString().split('T')[0])
        .order('date', { ascending: true });
      
      if (entriesError) {
        console.error('Error fetching habit entries:', entriesError);
        throw entriesError;
      }
      
      console.log('Habit entries fetched:', entries);
      
      // Group entries by date
      const entriesByDate = entries.reduce((acc: { [key: string]: Habit[] }, entry) => {
        if (!acc[entry.date]) {
          acc[entry.date] = [];
        }
        if (entry.habits) {
          acc[entry.date].push(entry.habits);
        }
        return acc;
      }, {});
      
      // Fill in missing dates with empty arrays
      const allDates = new Map();
      for (let d = new Date(startDate); d <= new Date(); d.setDate(d.getDate() + 1)) {
        const dateStr = d.toISOString().split('T')[0];
        allDates.set(dateStr, {
          date: dateStr,
          count: entriesByDate[dateStr]?.length || 0,
          habits: entriesByDate[dateStr] || []
        });
      }
      
      return Array.from(allDates.values());
    },
  });

  const toggleHabit = (habitId: string) => {
    console.log('Toggling habit:', habitId);
    setSelectedHabit(prevSelected => {
      const newSelected = prevSelected === habitId ? null : habitId;
      console.log('Selected habit:', newSelected);
      return newSelected;
    });
  };

  return (
    <section className="space-y-6">
      <div>
        <h2 className="text-lg font-semibold mb-4">2025, a better me...</h2>
        <HabitLegend 
          allHabits={allHabits}
          selectedHabit={selectedHabit}
          onHabitToggle={toggleHabit}
        />
      </div>
      
      {isLoading ? (
        <p>Loading habit data...</p>
      ) : (
        <div className="flex flex-wrap gap-1">
          {contributions.map((contribution) => (
            <ContributionCell
              key={contribution.date}
              contribution={contribution}
              selectedHabit={selectedHabit}
              totalHabits={allHabits.length}
            />
          ))}
        </div>
      )}
    </section>
  );
};