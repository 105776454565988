import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import { NewsletterForm } from "@/components/admin/subscribers/NewsletterForm";
import { SentEmailsList } from "@/components/admin/subscribers/SentEmailsList";
import { WelcomeEmailEditor } from "@/components/admin/subscribers/WelcomeEmailEditor";

const EmailAdmin = () => {
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [isSending, setIsSending] = useState(false);
  const { toast } = useToast();

  const { data: sentEmails, refetch: refetchSentEmails } = useQuery({
    queryKey: ['sent-emails'],
    queryFn: async () => {
      console.log('Fetching sent emails...');
      const { data, error } = await supabase
        .from('sent_emails')
        .select('*')
        .order('sent_at', { ascending: false });

      if (error) {
        console.error('Error fetching sent emails:', error);
        throw error;
      }

      console.log('Sent emails fetched:', data);
      return data;
    },
  });

  const handleSendEmail = async () => {
    try {
      setIsSending(true);
      console.log('Sending email to subscribers...');

      const { error: dbError } = await supabase
        .from('sent_emails')
        .insert([{ subject, content }]);

      if (dbError) throw dbError;

      const { error: emailError } = await supabase.functions.invoke('send-newsletter', {
        body: { 
          subject,
          content,
        }
      });

      if (emailError) throw emailError;

      toast({
        title: "Success",
        description: "Email sent to all subscribers.",
      });

      setSubject("");
      setContent("");
      refetchSentEmails();
    } catch (error) {
      console.error('Error sending email:', error);
      toast({
        title: "Error",
        description: "Failed to send email. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="container py-8 space-y-8">
      <h1 className="text-3xl font-semibold">Email Management</h1>

      <div className="space-y-8">
        <WelcomeEmailEditor />
        
        <NewsletterForm
          subject={subject}
          content={content}
          isSending={isSending}
          onSubjectChange={setSubject}
          onContentChange={setContent}
          onSend={handleSendEmail}
        />

        <SentEmailsList sentEmails={sentEmails} />
      </div>
    </div>
  );
};

export default EmailAdmin;