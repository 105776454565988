import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { format } from "date-fns";
import { Button } from "@/components/ui/button";
import { Trash2, Eye } from "lucide-react";
import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

interface Subscriber {
  id: string;
  email: string;
  created_at: string;
  user_agent?: string;
  referrer?: string;
  ip_address?: string;
  country?: string;
  city?: string;
}

export const SubscriberList = ({ subscribers }: { subscribers: Subscriber[] | null }) => {
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const handleDelete = async (id: string, email: string) => {
    try {
      setDeletingId(id);
      console.log('Attempting to delete subscriber:', email);
      
      const { error } = await supabase
        .from('subscribers')
        .delete()
        .eq('id', id);

      if (error) throw error;

      queryClient.setQueryData(['subscribers'], (oldData: Subscriber[] | null) => {
        if (!oldData) return null;
        return oldData.filter(subscriber => subscriber.id !== id);
      });

      toast({
        title: "Subscriber deleted",
        description: `${email} has been removed from the list.`,
      });
    } catch (error) {
      console.error('Error deleting subscriber:', error);
      toast({
        title: "Error",
        description: "Failed to delete subscriber. Please try again.",
        variant: "destructive",
      });
    } finally {
      setDeletingId(null);
    }
  };

  return (
    <section>
      <h2 className="text-xl font-medium mb-4">Subscribers</h2>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Email</TableHead>
            <TableHead>Location</TableHead>
            <TableHead>Subscribed On</TableHead>
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {subscribers?.map((subscriber) => (
            <TableRow key={subscriber.id}>
              <TableCell>{subscriber.email}</TableCell>
              <TableCell>
                {subscriber.city && subscriber.country 
                  ? `${subscriber.city}, ${subscriber.country}`
                  : 'Unknown location'}
              </TableCell>
              <TableCell>
                {format(new Date(subscriber.created_at), 'PPP')}
              </TableCell>
              <TableCell>
                <div className="flex gap-1">
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button
                        variant="ghost"
                        size="icon"
                        className="hover:bg-primary/10 hover:text-primary"
                      >
                        <Eye className="h-4 w-4" />
                      </Button>
                    </DialogTrigger>
                    <DialogContent>
                      <DialogHeader>
                        <DialogTitle>Subscriber Details</DialogTitle>
                      </DialogHeader>
                      <div className="space-y-4">
                        <div>
                          <h3 className="font-medium mb-1">Email</h3>
                          <p className="text-sm text-muted-foreground">{subscriber.email}</p>
                        </div>
                        <div>
                          <h3 className="font-medium mb-1">Location</h3>
                          <p className="text-sm text-muted-foreground">
                            {subscriber.city && subscriber.country 
                              ? `${subscriber.city}, ${subscriber.country}`
                              : 'Unknown location'}
                          </p>
                        </div>
                        <div>
                          <h3 className="font-medium mb-1">IP Address</h3>
                          <p className="text-sm text-muted-foreground">
                            {subscriber.ip_address || 'Not available'}
                          </p>
                        </div>
                        <div>
                          <h3 className="font-medium mb-1">Referrer</h3>
                          <p className="text-sm text-muted-foreground">
                            {subscriber.referrer || 'Direct visit'}
                          </p>
                        </div>
                        <div>
                          <h3 className="font-medium mb-1">Browser Info</h3>
                          <p className="text-sm text-muted-foreground break-all">
                            {subscriber.user_agent || 'Not available'}
                          </p>
                        </div>
                        <div>
                          <h3 className="font-medium mb-1">Subscribed On</h3>
                          <p className="text-sm text-muted-foreground">
                            {format(new Date(subscriber.created_at), 'PPP')}
                          </p>
                        </div>
                      </div>
                    </DialogContent>
                  </Dialog>
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <Button 
                        variant="ghost" 
                        size="icon"
                        className="hover:bg-destructive/10 hover:text-destructive"
                        disabled={deletingId === subscriber.id}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>Delete subscriber?</AlertDialogTitle>
                        <AlertDialogDescription>
                          This will permanently remove {subscriber.email} from your subscriber list.
                          This action cannot be undone.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction
                          className="bg-destructive hover:bg-destructive/90"
                          onClick={() => handleDelete(subscriber.id, subscriber.email)}
                        >
                          Delete
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </section>
  );
};