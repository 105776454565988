export const Footer = () => {
  return (
    <footer className="w-full">
      <div className="max-w-[65ch] mx-auto px-6 md:px-8 py-8">
        <div className="flex justify-between items-center">
          <p className="text-sm text-muted-foreground">
            © {new Date().getFullYear()} Scott Taylor
          </p>
          <div className="flex gap-4">
            <a
              href="https://x.com/ScottTaylor"
              target="_blank"
              rel="noopener noreferrer"
              className="text-muted-foreground hover:text-foreground transition-colors"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                shape-rendering="geometricPrecision" 
                text-rendering="geometricPrecision" 
                image-rendering="optimizeQuality" 
                fill-rule="evenodd" 
                clip-rule="evenodd" 
                viewBox="0 0 512 462.799"
                className="h-4 w-4"  // Reduced from h-5 w-5 to h-4 w-4
              >
                <path 
                  fill="currentColor"
                  fill-rule="nonzero" 
                  d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z"
                />
              </svg>
              <span className="sr-only">X (formerly Twitter)</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};