import { NoteForm } from "@/components/notes/NoteForm";
import { useToast } from "@/components/ui/use-toast";

const NotesAdmin = () => {
  const { toast } = useToast();

  const handleNoteAdded = () => {
    toast({
      title: "Success",
      description: "Note added successfully",
    });
  };

  return (
    <div className="max-w-2xl mx-auto">
      <h1 className="text-2xl font-semibold mb-8">Add Note</h1>
      <NoteForm onNoteAdded={handleNoteAdded} />
    </div>
  );
};

export default NotesAdmin;