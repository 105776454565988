import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { SubscriberList } from "@/components/admin/subscribers/SubscriberList";
import { UnsubscribedList } from "@/components/admin/subscribers/UnsubscribedList";
import { SubscriberGraph } from "@/components/admin/subscribers/SubscriberGraph";

const SubscriberAdmin = () => {
  const { data: subscribers } = useQuery({
    queryKey: ['subscribers'],
    queryFn: async () => {
      console.log('Fetching subscribers...');
      const { data, error } = await supabase
        .from('subscribers')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching subscribers:', error);
        throw error;
      }

      console.log('Subscribers fetched:', data);
      return data;
    },
  });

  const { data: unsubscribedUsers } = useQuery({
    queryKey: ['unsubscribed-users'],
    queryFn: async () => {
      console.log('Fetching unsubscribed users...');
      const { data, error } = await supabase
        .from('unsubscribed_users')
        .select('*')
        .order('unsubscribed_at', { ascending: false });

      if (error) {
        console.error('Error fetching unsubscribed users:', error);
        throw error;
      }

      console.log('Unsubscribed users fetched:', data);
      return data;
    },
  });

  return (
    <div className="container py-8 space-y-8">
      <h1 className="text-3xl font-semibold">Subscriber Management</h1>

      <div className="space-y-8">
        <SubscriberGraph />
        <SubscriberList subscribers={subscribers} />
        <UnsubscribedList unsubscribedUsers={unsubscribedUsers} />
      </div>
    </div>
  );
};

export default SubscriberAdmin;