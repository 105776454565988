
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Index from "./pages/Index";
import ArticlePage from "./pages/ArticlePage";
import AboutPage from "./pages/AboutPage";
import LogPage from "./pages/LogPage";
import NotesPage from "./pages/NotesPage";
import LoginPage from "./pages/LoginPage";
import { AdminLayout } from "./components/layouts/AdminLayout";
import EditorPage from "./pages/EditorPage";
import PostAdmin from "./pages/PostAdmin";
import HabitAdmin from "./pages/HabitAdmin";
import NotesAdmin from "./pages/NotesAdmin";
import SubscriberAdmin from "./pages/SubscriberAdmin";
import EmailAdmin from "./pages/EmailAdmin";
import SiteSettingsAdmin from "./pages/SiteSettingsAdmin";
import RssFeed from "./pages/RssFeed";
import Unsubscribe from "./pages/Unsubscribe";
import DashboardHome from "./pages/DashboardHome";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/log" element={<LogPage />} />
        <Route path="/notes" element={<NotesPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/feed.xml" element={<RssFeed />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="/:slug" element={<ArticlePage />} />
        
        {/* Auth redirect handling - send to admin */}
        <Route path="/auth/callback" element={<Navigate to="/admin" />} />
        
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<DashboardHome />} />
          <Route path="editor" element={<EditorPage />} />
          <Route path="posts" element={<PostAdmin />} />
          <Route path="habits" element={<HabitAdmin />} />
          <Route path="notes" element={<NotesAdmin />} />
          <Route path="subscribers" element={<SubscriberAdmin />} />
          <Route path="emails" element={<EmailAdmin />} />
          <Route path="site-settings" element={<SiteSettingsAdmin />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
