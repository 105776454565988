import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { supabase } from "@/integrations/supabase/client";
import { ImagePlus, Loader2 } from "lucide-react";

interface NoteFormProps {
  onNoteAdded: () => void;
}

export const NoteForm = ({ onNoteAdded }: NoteFormProps) => {
  const [highlight, setHighlight] = useState("");
  const [sourceTitle, setSourceTitle] = useState("");
  const [sourceUrl, setSourceUrl] = useState("");
  const [comment, setComment] = useState("");
  const [image, setImage] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const uploadImage = async (file: File): Promise<string | null> => {
    const fileExt = file.name.split('.').pop();
    const fileName = `${Math.random()}.${fileExt}`;
    const filePath = `${fileName}`;

    const { data, error } = await supabase.storage
      .from('article-images')
      .upload(filePath, file);

    if (error) {
      console.error('Error uploading image:', error);
      throw error;
    }

    const { data: { publicUrl } } = supabase.storage
      .from('article-images')
      .getPublicUrl(filePath);

    return publicUrl;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate that at least one source field or image is provided
    if (!sourceTitle && !sourceUrl && !image) {
      toast({
        title: "Error",
        description: "Please provide either a source title, URL, or image",
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();

      if (!user) {
        toast({
          title: "Error",
          description: "You must be logged in to add notes",
          variant: "destructive",
        });
        return;
      }

      let imageUrl = null;
      if (image) {
        imageUrl = await uploadImage(image);
      }

      const { error } = await supabase.from("notes").insert({
        highlight,
        source_title: sourceTitle || null,
        source_url: sourceUrl || null,
        comment: comment || null,
        image_url: imageUrl,
        user_id: user.id,
      });

      if (error) throw error;

      toast({
        title: "Success",
        description: "Note added successfully",
      });

      // Reset form
      setHighlight("");
      setSourceTitle("");
      setSourceUrl("");
      setComment("");
      setImage(null);
      
      // Trigger refresh of notes list
      onNoteAdded();
    } catch (error) {
      console.error("Error adding note:", error);
      toast({
        title: "Error",
        description: "Failed to add note",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="highlight">Highlight</Label>
        <Textarea
          id="highlight"
          value={highlight}
          onChange={(e) => setHighlight(e.target.value)}
          placeholder="Enter the text you want to highlight..."
          required
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="sourceTitle">Source Title</Label>
        <Input
          id="sourceTitle"
          value={sourceTitle}
          onChange={(e) => setSourceTitle(e.target.value)}
          placeholder="Book or article title"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="sourceUrl">Source URL</Label>
        <Input
          id="sourceUrl"
          type="url"
          value={sourceUrl}
          onChange={(e) => setSourceUrl(e.target.value)}
          placeholder="https://..."
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="image">Image</Label>
        <div className="flex items-center gap-4">
          <Input
            id="image"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="hidden"
          />
          <Button
            type="button"
            variant="outline"
            onClick={() => document.getElementById('image')?.click()}
            className="flex items-center gap-2"
          >
            <ImagePlus className="h-4 w-4" />
            {image ? 'Change Image' : 'Upload Image'}
          </Button>
          {image && <span className="text-sm text-muted-foreground">{image.name}</span>}
        </div>
      </div>

      <div className="space-y-2">
        <Label htmlFor="comment">Comment (optional)</Label>
        <Textarea
          id="comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Why is this highlight important to you?"
        />
      </div>

      <Button type="submit" disabled={isSubmitting} className="flex items-center gap-2">
        {isSubmitting && <Loader2 className="h-4 w-4 animate-spin" />}
        {isSubmitting ? "Adding..." : "Add Note"}
      </Button>
    </form>
  );
};