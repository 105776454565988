import { Editor } from "@/components/Editor";

const EditorPage = () => {
  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-semibold">New Article</h1>
      <Editor />
    </div>
  );
};

export default EditorPage;