import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";

export default function AboutPage() {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-1 w-full">
        <article className="max-w-[65ch] mx-auto px-6 md:px-8 py-12 prose dark:prose-invert">
          <h1 className="text-lg text-muted-foreground mb-8 font-normal">About Me</h1>
          
          <p>
            Hey! I'm Scott—a writer, thinker, and builder. Welcome to my little corner of the internet, where I share my thoughts, aspirations, and the journey that connects them. Over the past decade, I've started and sold some startups. In 2017, I was listed in Forbes 30U30. Recently, I led AI Products at a $320B asset manager. Now, I'm helping people build AI products with WonderWhat and just launched a coffee brand, Focus Coffee, as a passion project.
          </p>
        </article>
      </main>
      <Footer />
    </div>
  );
}