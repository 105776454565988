
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { supabase } from "@/integrations/supabase/client";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { X } from "lucide-react";

interface HabitEntry {
  id: string;
  date: string;
  comment: string;
  image_url: string | null;
  habits: {
    name: string;
    color: string;
  }
}

const LogPage = () => {
  const [entries, setEntries] = useState<HabitEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  useEffect(() => {
    const fetchEntries = async () => {
      try {
        console.log('Fetching habit entries with comments');
        const { data, error } = await supabase
          .from('habit_entries')
          .select(`
            id,
            date,
            comment,
            image_url,
            habits (
              name,
              color
            )
          `)
          .order('date', { ascending: false }) // Changed to descending order to show most recent first
          .not('comment', 'is', null);

        if (error) throw error;
        console.log('Fetched entries:', data);
        setEntries(data || []);
      } catch (error) {
        console.error('Error fetching entries:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEntries();
  }, []);

  return (
    <div className="min-h-screen bg-background flex flex-col">
      <Header />
      <main className="flex-grow">
        <div className="max-w-[65ch] mx-auto px-6 md:px-8 py-8 space-y-16">
          <section>
            <h2 className="text-lg text-muted-foreground mb-8">Habit Log</h2>
            {loading ? (
              <p className="text-muted-foreground">Loading entries...</p>
            ) : entries.length === 0 ? (
              <p className="text-muted-foreground">No habit entries with comments yet.</p>
            ) : (
              <div className="space-y-4">
                {entries.map((entry) => (
                  <div 
                    key={entry.id} 
                    className="p-4 rounded-lg border animate-fade-in space-y-3 relative"
                  >
                    <div className="flex items-start justify-between gap-4">
                      <div className="flex-grow">
                        <div className="flex items-center gap-2 mb-2">
                          <div 
                            className="w-3 h-3 rounded-sm"
                            style={{ backgroundColor: entry.habits.color }}
                          />
                          <span className="font-medium">{entry.habits.name}</span>
                          <span className="text-muted-foreground">
                            {format(new Date(entry.date), 'PP')}
                          </span>
                        </div>
                        <p className="text-sm text-muted-foreground">{entry.comment}</p>
                      </div>
                      {entry.image_url && (
                        <div 
                          onClick={() => setSelectedImage(entry.image_url)}
                          className="flex-shrink-0 cursor-pointer hover:opacity-80 transition-opacity"
                        >
                          <img
                            src={entry.image_url}
                            alt={`Thumbnail for ${entry.habits.name} on ${entry.date}`}
                            className="w-16 h-16 rounded-md object-cover"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </section>
        </div>
      </main>
      <Footer />

      <Dialog open={!!selectedImage} onOpenChange={() => setSelectedImage(null)}>
        <DialogContent className="max-w-3xl">
          {selectedImage && (
            <div className="relative">
              <button
                onClick={() => setSelectedImage(null)}
                className="absolute top-2 right-2 p-1 rounded-full bg-background/80 hover:bg-background/90 transition-colors"
              >
                <X className="h-4 w-4" />
              </button>
              <img
                src={selectedImage}
                alt="Full size image"
                className="w-full rounded-lg"
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LogPage;
