
import { useQuery } from "@tanstack/react-query";
import { ContributionGraph } from "@/components/ContributionGraph";
import { ArticleCard } from "@/components/ArticleCard";
import { supabase } from "@/integrations/supabase/client";
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { useToast } from "@/components/ui/use-toast";
import { Article } from "@/integrations/supabase/types/article";
import { NewsletterSignup } from "@/components/NewsletterSignup";
import { Toaster } from "@/components/ui/toaster";

const Index = () => {
  const { toast } = useToast();
  
  const { data: articles = [], isLoading, error } = useQuery<Article[]>({
    queryKey: ['articles'],
    queryFn: async () => {
      console.log('Fetching articles...');
      const { data, error } = await supabase
        .from('articles')
        .select('*')
        .eq('draft', false)
        .order('created_at', { ascending: false });
      
      if (error) {
        console.error('Error fetching articles:', error);
        toast({
          title: "Error",
          description: "Failed to load articles. Please try again later.",
          variant: "destructive",
        });
        throw error;
      }
      
      console.log('Articles fetched successfully:', data);
      if (!data || data.length === 0) {
        console.log('No articles found in the response');
      }
      return data;
    }
  });

  // Get the latest article
  const latestArticle = articles?.[0];

  return (
    <div className="min-h-screen bg-background flex flex-col">
      <Header />
      <main className="flex-grow">
        <div className="max-w-[65ch] mx-auto px-6 md:px-8 py-8 space-y-16">
          {/* Habit Tracker */}
          <section>
            <ContributionGraph />
          </section>

          {/* Latest Section */}
          <section>
            <h2 className="text-lg text-muted-foreground mb-8">Latest</h2>
            <div>
              {isLoading ? (
                <p>Loading articles...</p>
              ) : error ? (
                <p className="text-destructive">Failed to load articles. Please try again later.</p>
              ) : articles && articles.length > 0 ? (
                <ArticleCard 
                  key={latestArticle.slug}
                  title={latestArticle.title}
                  excerpt={latestArticle.excerpt}
                  date={latestArticle.created_at}
                  slug={latestArticle.slug}
                />
              ) : (
                <p>No published articles found</p>
              )}
            </div>
          </section>

          {/* Writing Section */}
          <section>
            <h2 className="text-lg text-muted-foreground mb-6">Writing</h2>
            <div className="space-y-4">
              {articles && articles.length > 0 ? (
                articles.map((article) => (
                  <div key={article.slug} className="flex gap-4">
                    <span className="text-muted-foreground whitespace-nowrap">
                      {new Date(article.created_at).getFullYear()} · {String(new Date(article.created_at).getMonth() + 1).padStart(2, '0')}
                    </span>
                    <a href={`/${article.slug}`} className="hover:underline">
                      {article.title}
                    </a>
                  </div>
                ))
              ) : null}
            </div>
          </section>
        </div>
      </main>
      <NewsletterSignup />
      <Footer />
      <Toaster />
    </div>
  );
};

export default Index;
