
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card } from "@/components/ui/card";
import { BarChart, Calendar, FileText, Users, Mails, ListTodo } from "lucide-react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { ArticleList } from "@/components/admin/ArticleList";
import { SubscriberGraph } from "@/components/admin/subscribers/SubscriberGraph";

// Stats card component
const StatCard = ({ 
  title, 
  value, 
  icon: Icon, 
  description, 
  color = "bg-[#E5DEFF]",
  iconColor = "text-[#6E59A5]",
  link
}: { 
  title: string; 
  value: string | number; 
  icon: React.ElementType; 
  description: string;
  color?: string;
  iconColor?: string;
  link?: string;
}) => {
  const CardContent = () => (
    <div className="p-6">
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-sm font-medium text-muted-foreground">{title}</h3>
          <p className="text-3xl font-bold mt-2">{value}</p>
        </div>
        <div className={`${color} p-3 rounded-lg ${iconColor}`}>
          <Icon className="h-5 w-5" />
        </div>
      </div>
      <p className="text-sm text-muted-foreground mt-4">{description}</p>
    </div>
  );

  if (link) {
    return (
      <Link to={link} className="block">
        <Card className="h-full transition-all hover:shadow-md">
          <CardContent />
        </Card>
      </Link>
    );
  }

  return (
    <Card className="h-full">
      <CardContent />
    </Card>
  );
};

const DashboardHome = () => {
  const today = new Date();
  
  // Fetch article stats
  const { data: articles = [] } = useQuery({
    queryKey: ['dashboard-articles'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('articles')
        .select('*')
        .order('created_at', { ascending: false });
      
      if (error) {
        console.error('Error fetching articles:', error);
        throw error;
      }
      
      return data || [];
    },
  });

  // Fetch subscriber stats
  const { data: subscribers = [] } = useQuery({
    queryKey: ['dashboard-subscribers'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('subscribers')
        .select('*');
      
      if (error) {
        console.error('Error fetching subscribers:', error);
        throw error;
      }
      
      return data || [];
    },
  });

  // Fetch habit stats for today
  const { data: habitEntries = [] } = useQuery({
    queryKey: ['dashboard-habits'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('habit_entries')
        .select('*');
      
      if (error) {
        console.error('Error fetching habit entries:', error);
        throw error;
      }
      
      return data || [];
    },
  });

  // Fetch sent emails
  const { data: sentEmails = [] } = useQuery({
    queryKey: ['dashboard-emails'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('sent_emails')
        .select('*');
      
      if (error) {
        console.error('Error fetching sent emails:', error);
        throw error;
      }
      
      return data || [];
    },
  });

  // Count draft and published articles
  const publishedCount = articles.filter(article => !article.draft).length;
  const draftCount = articles.filter(article => article.draft).length;
  
  // Calculate recent activity
  const recentDate = new Date();
  recentDate.setDate(recentDate.getDate() - 7);
  
  const recentSubscribers = subscribers.filter(
    sub => new Date(sub.created_at) >= recentDate
  ).length;

  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-3xl font-semibold text-[#1A1F2C]">Dashboard</h1>
          <p className="text-[#8A898C] mt-1">Welcome back! Here's an overview of your site</p>
        </div>
        <div className="text-[#6E59A5] font-medium">
          {format(today, 'EEEE, MMMM d, yyyy')}
        </div>
      </div>
      
      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <StatCard
          title="Published Posts"
          value={publishedCount}
          icon={FileText}
          description="Total published articles"
          color="bg-[#F2FCE2]"
          iconColor="text-green-600"
          link="/admin/posts"
        />
        <StatCard
          title="Draft Posts"
          value={draftCount}
          icon={FileText}
          description="Articles in progress"
          color="bg-[#FEF7CD]"
          iconColor="text-amber-600"
          link="/admin/posts"
        />
        <StatCard
          title="Subscribers"
          value={subscribers.length}
          icon={Users}
          description={`${recentSubscribers} new in the last 7 days`}
          color="bg-[#D3E4FD]"
          iconColor="text-blue-600"
          link="/admin/subscribers"
        />
        <StatCard
          title="Newsletter"
          value={sentEmails.length}
          icon={Mails}
          description="Total emails sent"
          color="bg-[#FFDEE2]"
          iconColor="text-pink-600"
          link="/admin/emails"
        />
      </div>
      
      {/* Charts and Tables Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Subscriber Growth Chart */}
        <Card className="p-6">
          <div className="flex items-center gap-2 mb-4">
            <BarChart className="h-5 w-5 text-[#6E59A5]" />
            <h2 className="text-lg font-medium">Subscriber Growth</h2>
          </div>
          <SubscriberGraph />
        </Card>
        
        {/* Recent Articles */}
        <Card className="p-6">
          <div className="flex items-center gap-2 mb-4">
            <FileText className="h-5 w-5 text-[#6E59A5]" />
            <h2 className="text-lg font-medium">Recent Articles</h2>
          </div>
          <ArticleList 
            articles={articles.slice(0, 5)} 
            onEdit={() => {}} 
            onDelete={() => {}}
            showDraftsOnly={false}
            minimal={true}
          />
          <div className="mt-4 text-right">
            <Link 
              to="/admin/posts" 
              className="text-sm text-[#6E59A5] hover:underline"
            >
              View all posts →
            </Link>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default DashboardHome;
