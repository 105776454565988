
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { format, addDays } from "date-fns";
import { useState, useEffect } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Calendar } from "@/components/ui/calendar";
import { 
  CheckCircle, 
  Calendar as CalendarIcon, 
  Image as ImageIcon, 
  MessageSquare, 
  LoaderCircle,
  PlusCircle,
  ChevronRight,
  ChevronLeft
} from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@/components/ui/popover";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface Habit {
  id: string;
  name: string;
  color?: string;
}

interface HabitListProps {
  habits: Habit[];
  selectedDate: Date | undefined;
  userId: string;
}

interface CompletedDates {
  [habitId: string]: Date[];
}

export const HabitList = ({ habits, selectedDate, userId }: HabitListProps) => {
  const { toast } = useToast();
  const [comments, setComments] = useState<{ [key: string]: string }>({});
  const [images, setImages] = useState<{ [key: string]: File }>({});
  const [uploading, setUploading] = useState<{ [key: string]: boolean }>({});
  const [showComment, setShowComment] = useState<{ [key: string]: boolean }>({});
  const [showMultiDateSelect, setShowMultiDateSelect] = useState<{ [key: string]: boolean }>({});
  const [selectedDateRange, setSelectedDateRange] = useState<{ [key: string]: Date[] }>({});
  const [completedDates, setCompletedDates] = useState<CompletedDates>({});

  // Fetch completed habit dates when habits or selectedDate changes
  useEffect(() => {
    if (habits.length > 0 && userId) {
      fetchCompletedDates();
    }
  }, [habits, userId, selectedDate]);

  // When multi-date select is toggled, fetch the completed dates for that habit
  useEffect(() => {
    const habitWithMultiDateEnabled = habits.find(
      habit => showMultiDateSelect[habit.id]
    );
    
    if (habitWithMultiDateEnabled) {
      fetchCompletedDatesForHabit(habitWithMultiDateEnabled.id);
    }
  }, [showMultiDateSelect]);

  const fetchCompletedDates = async () => {
    try {
      // Create an empty object to store completed dates for each habit
      const newCompletedDates: CompletedDates = {};
      
      // Initialize with empty arrays for each habit
      habits.forEach(habit => {
        newCompletedDates[habit.id] = [];
      });
      
      // Fetch all completed dates for all habits
      for (const habit of habits) {
        await fetchCompletedDatesForHabit(habit.id, newCompletedDates);
      }
      
      // No need to set state here as it's set in fetchCompletedDatesForHabit
    } catch (error) {
      console.error('Error fetching completed dates:', error);
    }
  };

  const fetchCompletedDatesForHabit = async (habitId: string, existingDates?: CompletedDates) => {
    try {
      const { data, error } = await supabase
        .from('habit_entries')
        .select('date')
        .eq('habit_id', habitId)
        .eq('user_id', userId);

      if (error) throw error;

      const dates = (data || []).map(entry => new Date(entry.date));
      
      if (existingDates) {
        // Add to existing dates object
        existingDates[habitId] = dates;
        setCompletedDates(existingDates);
      } else {
        // Update just this habit's dates
        setCompletedDates(prev => ({
          ...prev,
          [habitId]: dates
        }));
      }
    } catch (error) {
      console.error(`Error fetching completed dates for habit ${habitId}:`, error);
    }
  };

  const handleImageChange = (habitId: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImages(prev => ({
        ...prev,
        [habitId]: file
      }));
    }
  };

  const toggleCommentSection = (habitId: string) => {
    setShowComment(prev => ({
      ...prev,
      [habitId]: !prev[habitId]
    }));
  };

  const toggleMultiDateSelect = (habitId: string) => {
    setShowMultiDateSelect(prev => {
      const newState = {
        ...prev,
        [habitId]: !prev[habitId]
      };
      
      // Initialize date range with current selected date if toggling on
      if (newState[habitId] && selectedDate) {
        setSelectedDateRange(prev => ({
          ...prev,
          [habitId]: [selectedDate]
        }));
      }
      
      return newState;
    });
  };
  
  // Handle selecting a date in the multi-date calendar
  const handleDateSelect = (habitId: string, dates: Date[] | undefined) => {
    if (!dates) return;
    
    setSelectedDateRange(prev => ({
      ...prev,
      [habitId]: dates
    }));
  };

  const navigateWeek = (habitId: string, direction: 'forward' | 'backward') => {
    if (!selectedDate) return;
    
    const offset = direction === 'forward' ? 7 : -7;
    
    // Update selected dates
    setSelectedDateRange(prev => {
      const currentDates = [...(prev[habitId] || [])];
      return {
        ...prev,
        [habitId]: currentDates.map(date => addDays(date, offset))
      };
    });
  };

  const uploadImage = async (file: File, habitId: string) => {
    try {
      const fileExt = file.name.split('.').pop();
      const fileName = `${crypto.randomUUID()}.${fileExt}`;
      const filePath = `${userId}/${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from('article-images')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('article-images')
        .getPublicUrl(filePath);

      return publicUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const markHabitComplete = async (habitId: string) => {
    if (!selectedDate || !userId) return;

    try {
      setUploading(prev => ({ ...prev, [habitId]: true }));
      
      // If multi-date selection is active, use those dates, otherwise use the single selectedDate
      const datesToMark = showMultiDateSelect[habitId] && selectedDateRange[habitId]?.length 
                        ? selectedDateRange[habitId] 
                        : [selectedDate];
      
      console.log('Marking habit complete for dates:', datesToMark.map(d => d.toISOString().split('T')[0]));
      
      let imageUrl = null;
      if (images[habitId]) {
        imageUrl = await uploadImage(images[habitId], habitId);
      }

      // Process each date
      for (const date of datesToMark) {
        // Check if the habit is already marked for this date
        const { data: existingEntry, error: checkError } = await supabase
          .from('habit_entries')
          .select('*')
          .eq('date', date.toISOString().split('T')[0])
          .eq('habit_id', habitId)
          .eq('user_id', userId)
          .maybeSingle();

        if (checkError) {
          console.error('Error checking habit entry:', checkError);
          continue; // Skip this date but continue with others
        }

        if (existingEntry) {
          console.log(`Habit already marked for date: ${date.toISOString().split('T')[0]}`);
          continue; // Skip this date but continue with others
        }

        // Insert habit entry for this date
        const { error: insertError } = await supabase
          .from('habit_entries')
          .insert({
            date: date.toISOString().split('T')[0],
            habit_id: habitId,
            user_id: userId,
            count: 1,
            comment: comments[habitId] || null,
            image_url: imageUrl
          });

        if (insertError) {
          console.error('Error marking habit:', insertError);
          // Continue with other dates despite error
        }
      }

      // Clear form data
      setComments(prev => ({ ...prev, [habitId]: '' }));
      setImages(prev => {
        const newImages = { ...prev };
        delete newImages[habitId];
        return newImages;
      });
      setShowMultiDateSelect(prev => ({ ...prev, [habitId]: false }));
      setSelectedDateRange(prev => ({ ...prev, [habitId]: [] }));
      
      // Refresh completed dates
      fetchCompletedDatesForHabit(habitId);

      toast({
        title: "Success",
        description: `Habit marked as completed for ${datesToMark.length} date(s)`,
      });
    } catch (error) {
      console.error('Error marking habit:', error);
      toast({
        title: "Error",
        description: "Failed to mark habit. Please try again.",
        variant: "destructive",
      });
    } finally {
      setUploading(prev => ({ ...prev, [habitId]: false }));
    }
  };

  // Function to determine if a date is already completed
  const isDateCompleted = (habitId: string, date: Date): boolean => {
    return (completedDates[habitId] || []).some(
      completedDate => 
        completedDate.getFullYear() === date.getFullYear() &&
        completedDate.getMonth() === date.getMonth() &&
        completedDate.getDate() === date.getDate()
    );
  };

  // Custom day renderer for the calendar
  const renderDay = (habitId: string, date: Date) => {
    const isCompleted = isDateCompleted(habitId, date);
    
    if (isCompleted) {
      return (
        <div className="flex h-9 w-9 items-center justify-center rounded-full bg-[#E5F8EB] ring-1 ring-[#34D399]">
          <CheckCircle className="h-4 w-4 text-[#34D399]" />
        </div>
      );
    }
    
    return date.getDate();
  };

  return (
    <div>
      <h2 className="text-lg font-semibold mb-4 text-[#1A1F2C]">
        Mark Habits Complete for {selectedDate ? format(selectedDate, 'PP') : 'selected date'}
      </h2>
      {habits.length === 0 ? (
        <Card className="bg-[#F9F7FF] border-[#E5DEFF]">
          <CardContent className="pt-6">
            <p className="text-muted-foreground text-center">No habits created yet. Create one above!</p>
          </CardContent>
        </Card>
      ) : (
        <div className="space-y-4">
          {habits.map((habit) => (
            <Card key={habit.id} className="border-[#E5DEFF] overflow-hidden">
              <CardHeader className="bg-[#F9F7FF] pb-4 pt-4">
                <CardTitle className="text-lg text-[#403E43] flex justify-between items-center">
                  <span style={{ color: habit.color || '#6E59A5' }}>{habit.name}</span>
                  <Button 
                    onClick={() => markHabitComplete(habit.id)}
                    disabled={!selectedDate || uploading[habit.id] || (showMultiDateSelect[habit.id] && (!selectedDateRange[habit.id] || selectedDateRange[habit.id].length === 0))}
                    className="bg-[#9b87f5] hover:bg-[#8B5CF6] gap-2"
                  >
                    {uploading[habit.id] ? (
                      <>
                        <LoaderCircle className="h-4 w-4 animate-spin" />
                        Processing...
                      </>
                    ) : (
                      <>
                        <CheckCircle className="h-4 w-4" />
                        {showMultiDateSelect[habit.id] 
                          ? `Mark Complete (${selectedDateRange[habit.id]?.length || 0} dates)`
                          : "Mark Complete" 
                        }
                      </>
                    )}
                  </Button>
                </CardTitle>
                <CardDescription className="flex gap-2 mt-2">
                  <Button 
                    variant="outline" 
                    size="sm" 
                    onClick={() => toggleMultiDateSelect(habit.id)}
                    className={`gap-1 ${showMultiDateSelect[habit.id] ? 'bg-[#E5DEFF] text-[#6E59A5] border-[#9b87f5]' : 'border-[#E5DEFF] text-[#8A898C]'}`}
                  >
                    <CalendarIcon className="h-4 w-4" />
                    {showMultiDateSelect[habit.id] ? "Hide Date Selection" : "Select Multiple Dates"}
                  </Button>
                  
                  <Button 
                    variant="outline" 
                    size="sm" 
                    onClick={() => toggleCommentSection(habit.id)}
                    className={`gap-1 ${showComment[habit.id] ? 'bg-[#E5DEFF] text-[#6E59A5] border-[#9b87f5]' : 'border-[#E5DEFF] text-[#8A898C]'}`}
                  >
                    <MessageSquare className="h-4 w-4" />
                    {showComment[habit.id] ? "Hide Details" : "Add Details"}
                  </Button>
                </CardDescription>
              </CardHeader>
              
              {showMultiDateSelect[habit.id] && (
                <CardContent className="pt-4 bg-[#F1F0FB]">
                  <div className="mb-2 flex justify-between items-center">
                    <Button 
                      variant="ghost" 
                      size="sm" 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent any form submission
                        navigateWeek(habit.id, 'backward');
                      }}
                      className="text-[#6E59A5]"
                    >
                      <ChevronLeft className="h-4 w-4 mr-1" />
                      Previous Week
                    </Button>
                    <Button 
                      variant="ghost" 
                      size="sm" 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent any form submission
                        navigateWeek(habit.id, 'forward');
                      }}
                      className="text-[#6E59A5]"
                    >
                      Next Week
                      <ChevronRight className="h-4 w-4 ml-1" />
                    </Button>
                  </div>
                  <Calendar
                    mode="multiple"
                    selected={selectedDateRange[habit.id] || []}
                    onSelect={(dates) => {
                      // Prevent default to avoid any form submission
                      handleDateSelect(habit.id, dates);
                    }}
                    modifiers={{
                      completed: (date) => isDateCompleted(habit.id, date)
                    }}
                    modifiersClassNames={{
                      completed: "bg-[#E5F8EB] text-[#34D399] font-medium hover:bg-[#D1F7DF] hover:text-[#34D399]"
                    }}
                    className="rounded-md border bg-white pointer-events-auto"
                    classNames={{
                      day_selected: "bg-[#9b87f5] text-white hover:bg-[#8B5CF6] hover:text-white focus:bg-[#8B5CF6] focus:text-white",
                      day_today: "bg-[#F9F7FF] text-[#6E59A5]"
                    }}
                  />
                </CardContent>
              )}
              
              {showComment[habit.id] && (
                <CardContent className="pt-4">
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor={`comment-${habit.id}`} className="text-[#403E43]">Add a comment (optional)</Label>
                      <Textarea
                        id={`comment-${habit.id}`}
                        placeholder="How did it go? What did you learn?"
                        value={comments[habit.id] || ''}
                        onChange={(e) => setComments(prev => ({
                          ...prev,
                          [habit.id]: e.target.value
                        }))}
                        className="resize-none border-[#E5DEFF] focus-visible:ring-[#9b87f5]"
                      />
                    </div>
                    
                    <div className="space-y-2">
                      <Label htmlFor={`image-${habit.id}`} className="flex items-center gap-2 text-[#403E43]">
                        <ImageIcon className="h-4 w-4 text-[#6E59A5]" />
                        Add an image (optional)
                      </Label>
                      <Input
                        id={`image-${habit.id}`}
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageChange(habit.id, e)}
                        className="cursor-pointer border-[#E5DEFF] focus-visible:ring-[#9b87f5]"
                      />
                      {images[habit.id] && (
                        <p className="text-sm text-[#6E59A5] bg-[#F9F7FF] p-2 rounded flex items-center">
                          <PlusCircle className="h-4 w-4 mr-2" />
                          Selected: {images[habit.id].name}
                        </p>
                      )}
                    </div>
                  </div>
                </CardContent>
              )}
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};
