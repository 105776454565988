import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { format } from "date-fns";

interface UnsubscribedUser {
  id: string;
  email: string;
  unsubscribed_at: string;
}

export const UnsubscribedList = ({ unsubscribedUsers }: { unsubscribedUsers: UnsubscribedUser[] | null }) => {
  return (
    <div>
      <h2 className="text-xl font-medium mb-4">Unsubscribed Users</h2>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Email</TableHead>
            <TableHead>Unsubscribed On</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {unsubscribedUsers?.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                {format(new Date(user.unsubscribed_at), 'PPP')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};