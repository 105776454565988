import { Header } from "@/components/Header";

export const ArticleLoading = () => {
  return (
    <div className="min-h-screen bg-background">
      <Header />
      <div className="container py-8">
        <div className="animate-pulse space-y-4">
          <div className="h-8 w-3/4 bg-muted rounded"></div>
          <div className="h-4 w-1/4 bg-muted rounded"></div>
          <div className="space-y-2">
            <div className="h-4 bg-muted rounded"></div>
            <div className="h-4 bg-muted rounded"></div>
            <div className="h-4 w-3/4 bg-muted rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};