import { Header } from "@/components/Header";

export const ArticleNotFound = () => {
  return (
    <div className="min-h-screen bg-background">
      <Header />
      <div className="container py-8">
        <h1 className="text-2xl">Article not found</h1>
      </div>
    </div>
  );
};