import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { WysiwygEditor } from "../WysiwygEditor";

interface NewsletterFormProps {
  subject: string;
  content: string;
  isSending: boolean;
  onSubjectChange: (value: string) => void;
  onContentChange: (value: string) => void;
  onSend: () => void;
}

export const NewsletterForm = ({
  subject,
  content,
  isSending,
  onSubjectChange,
  onContentChange,
  onSend
}: NewsletterFormProps) => {
  return (
    <div className="space-y-4">
      <h2 className="text-xl font-medium">Send Newsletter</h2>
      <div className="space-y-4">
        <Input
          placeholder="Subject"
          value={subject}
          onChange={(e) => onSubjectChange(e.target.value)}
        />
        <WysiwygEditor
          content={content}
          onChange={onContentChange}
          placeholder="Write your newsletter content..."
        />
        <Button 
          onClick={onSend} 
          disabled={isSending || !subject || !content}
        >
          {isSending ? "Sending..." : "Send to All Subscribers"}
        </Button>
      </div>
    </div>
  );
};