
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import { ArticleEditor } from "@/components/admin/ArticleEditor";
import { ArticleList } from "@/components/admin/ArticleList";
import { Article } from "@/integrations/supabase/types/article";
import { Button } from "@/components/ui/button";
import { Loader2, FileText, FilePen } from "lucide-react";

const ADMIN_USER_ID = 'a774ff8e-90e7-43aa-97ed-4fdb35415b0c';

const PostAdmin = () => {
  const [editingArticle, setEditingArticle] = useState<Article | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const { toast } = useToast();

  const { data: articles, refetch, isLoading, error } = useQuery({
    queryKey: ['admin-articles'],
    queryFn: async () => {
      console.log('Fetching articles for admin...');
      const { data, error } = await supabase
        .from('articles')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching articles:', error);
        toast({
          title: "Error",
          description: "Failed to load articles: " + error.message,
          variant: "destructive",
        });
        throw error;
      }

      console.log('Articles fetched:', data);

      return data || [];
    },
    refetchOnWindowFocus: false,
  });

  const handleGenerateDraft = async () => {
    try {
      setIsGenerating(true);
      console.log('Generating draft blog post...');
      
      const { data, error } = await supabase.functions.invoke('generate-daily-blog', {
        method: 'POST',
      });
      
      if (error) throw error;

      console.log('Generation response:', data);
      
      if (!data?.article) {
        throw new Error('No article was generated');
      }

      await refetch();
      
      toast({
        title: "Success",
        description: "New draft blog post generated successfully.",
      });

    } catch (error) {
      console.error('Error generating draft:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to generate draft blog post.",
        variant: "destructive",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSave = async (title: string, content: string, date: Date, isDraft: boolean) => {
    try {
      console.log('Updating article:', {
        id: editingArticle?.id,
        title,
        content,
        date,
        isDraft
      });
      
      const { error } = await supabase
        .from('articles')
        .update({ 
          title,
          content,
          created_at: date.toISOString(),
          excerpt: content.slice(0, 150) + (content.length > 150 ? '...' : ''),
          draft: isDraft,
          user_id: ADMIN_USER_ID
        })
        .eq('id', editingArticle?.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: isDraft ? "Draft saved successfully." : "Article updated successfully.",
      });

      setEditingArticle(null);
      refetch();
    } catch (error) {
      console.error('Error updating article:', error);
      toast({
        title: "Error",
        description: "Failed to update article.",
        variant: "destructive",
      });
    }
  };

  const handleDelete = async (id: string) => {
    try {
      console.log('Deleting article:', id);
      
      const { error } = await supabase
        .from('articles')
        .delete()
        .eq('id', id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Article deleted successfully.",
      });

      refetch();
    } catch (error) {
      console.error('Error deleting article:', error);
      toast({
        title: "Error",
        description: "Failed to delete article.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-3xl font-semibold text-[#1A1F2C]">Manage Posts</h1>
          <p className="text-[#8A898C] mt-1">Create, edit and delete blog posts</p>
        </div>
        <Button 
          onClick={handleGenerateDraft} 
          disabled={isGenerating}
          className="bg-[#9b87f5] hover:bg-[#8B5CF6] gap-2"
        >
          {isGenerating ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Generating...
            </>
          ) : (
            <>
              <FilePen className="h-4 w-4" />
              Generate New Draft
            </>
          )}
        </Button>
      </div>
      
      {isLoading && (
        <div className="flex justify-center py-8">
          <Loader2 className="h-8 w-8 animate-spin text-[#9b87f5]" />
        </div>
      )}
      
      {error && (
        <div className="p-4 border border-destructive bg-destructive/10 rounded-md text-destructive">
          Failed to load articles. Please try refreshing the page.
        </div>
      )}

      {editingArticle && (
        <ArticleEditor
          article={editingArticle}
          onSave={handleSave}
          onCancel={() => setEditingArticle(null)}
        />
      )}

      {articles && !isLoading && (
        <div className="space-y-8">
          <div className="bg-[#F9F7FF] p-4 rounded-lg">
            <div className="flex items-center gap-2 mb-3">
              <FileText className="h-5 w-5 text-[#8B5CF6]" />
              <h2 className="text-xl font-medium text-[#6E59A5]">Draft Articles</h2>
            </div>
            <ArticleList
              articles={articles}
              onEdit={setEditingArticle}
              onDelete={handleDelete}
              showDraftsOnly={true}
            />
          </div>

          <div className="bg-[#F1F0FB] p-4 rounded-lg">
            <div className="flex items-center gap-2 mb-3">
              <FileText className="h-5 w-5 text-[#403E43]" />
              <h2 className="text-xl font-medium text-[#403E43]">Published Articles</h2>
            </div>
            <ArticleList
              articles={articles}
              onEdit={setEditingArticle}
              onDelete={handleDelete}
              showDraftsOnly={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PostAdmin;
