import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Trash2 } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

interface Habit {
  id: string;
  name: string;
  color: string;
}

interface HabitFormProps {
  userId: string;
  onHabitCreated: () => void;
  habits: Habit[];
}

const HABIT_COLORS = [
  '#9be9a8', // Default green
  '#8B5CF6', // Vivid Purple
  '#D946EF', // Magenta Pink
  '#F97316', // Bright Orange
  '#0EA5E9', // Ocean Blue
  '#F2FCE2', // Soft Green
  '#FEF7CD', // Soft Yellow
  '#FEC6A1', // Soft Orange
  '#E5DEFF', // Soft Purple
  '#FFDEE2', // Soft Pink
];

export const HabitForm = ({ userId, onHabitCreated, habits }: HabitFormProps) => {
  const [newHabitName, setNewHabitName] = useState("");
  const [selectedColor, setSelectedColor] = useState(HABIT_COLORS[0]);
  const { toast } = useToast();

  const createHabit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newHabitName.trim()) return;

    try {
      console.log('Creating new habit:', newHabitName, 'with color:', selectedColor);
      const { data, error } = await supabase
        .from('habits')
        .insert([
          { 
            name: newHabitName, 
            user_id: userId,
            color: selectedColor 
          }
        ])
        .select()
        .single();

      if (error) throw error;

      console.log('Created habit:', data);
      setNewHabitName("");
      setSelectedColor(HABIT_COLORS[0]);
      onHabitCreated();
      toast({
        title: "Success",
        description: "Habit created successfully",
      });
    } catch (error) {
      console.error('Error creating habit:', error);
      toast({
        title: "Error",
        description: "Failed to create habit. Please try again.",
        variant: "destructive",
      });
    }
  };

  const deleteHabit = async (habitId: string) => {
    try {
      console.log('Deleting habit:', habitId);
      
      // First delete all habit entries
      const { error: entriesError } = await supabase
        .from('habit_entries')
        .delete()
        .eq('habit_id', habitId);

      if (entriesError) throw entriesError;

      // Then delete the habit itself
      const { error: habitError } = await supabase
        .from('habits')
        .delete()
        .eq('id', habitId);

      if (habitError) throw habitError;

      onHabitCreated(); // Refresh the list
      toast({
        title: "Success",
        description: "Habit and all its entries deleted successfully",
      });
    } catch (error) {
      console.error('Error deleting habit:', error);
      toast({
        title: "Error",
        description: "Failed to delete habit. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div>
      <h2 className="text-lg font-semibold mb-4">Create New Habit</h2>
      <form onSubmit={createHabit} className="space-y-4">
        <div>
          <Input
            value={newHabitName}
            onChange={(e) => setNewHabitName(e.target.value)}
            placeholder="Enter habit name"
            className="w-full"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-2">Select Color</label>
          <div className="flex flex-wrap gap-2">
            {HABIT_COLORS.map((color) => (
              <button
                key={color}
                type="button"
                onClick={() => setSelectedColor(color)}
                className={`w-8 h-8 rounded-md transition-all ${
                  selectedColor === color ? 'ring-2 ring-offset-2 ring-black' : ''
                }`}
                style={{ backgroundColor: color }}
                aria-label={`Select color ${color}`}
              />
            ))}
          </div>
        </div>
        <Button type="submit" className="w-full">Create</Button>
      </form>

      <div className="mt-8">
        <h3 className="text-lg font-semibold mb-4">Manage Habits</h3>
        <div className="space-y-2">
          {habits?.map((habit) => (
            <div key={habit.id} className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
              <div className="flex items-center gap-2">
                <div 
                  className="w-4 h-4 rounded-sm"
                  style={{ backgroundColor: habit.color }}
                />
                <span>{habit.name}</span>
              </div>
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button variant="ghost" size="icon">
                    <Trash2 className="h-4 w-4 text-red-500" />
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                      This will permanently delete the habit "{habit.name}" and all its tracked entries.
                      This action cannot be undone.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => deleteHabit(habit.id)}
                      className="bg-red-500 hover:bg-red-600"
                    >
                      Delete
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};