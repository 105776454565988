import { useEffect, useState } from "react";
import { format } from "date-fns";
import { ExternalLink, Trash2 } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { XProfileInfo } from "@/components/notes/XProfileInfo";

interface Note {
  id: string;
  highlight: string;
  source_title: string | null;
  source_url: string | null;
  comment: string | null;
  created_at: string;
  image_url: string | null;
}

const NotesPage = () => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const { toast } = useToast();

  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user || null);
    });

    return () => subscription.unsubscribe();
  }, []);

  const fetchNotes = async () => {
    try {
      console.log('Fetching reading notes');
      const { data, error } = await supabase
        .from('notes')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      console.log('Fetched notes:', data);
      setNotes(data || []);
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (noteId: string) => {
    try {
      const { error } = await supabase
        .from('notes')
        .delete()
        .eq('id', noteId);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Note deleted successfully",
      });

      // Refresh notes list
      fetchNotes();
    } catch (error) {
      console.error('Error deleting note:', error);
      toast({
        title: "Error",
        description: "Failed to delete note",
        variant: "destructive",
      });
    }
  };

  const getDomainFromUrl = (url: string) => {
    try {
      const domain = new URL(url).hostname.replace('www.', '');
      return domain.toLowerCase();
    } catch {
      return url;
    }
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  return (
    <div className="min-h-screen bg-background flex flex-col">
      <Header />
      <main className="flex-grow">
        <div className="max-w-[65ch] mx-auto px-6 md:px-8 py-8 space-y-16">
          <section>
            <h2 className="text-lg text-muted-foreground mb-8">Reading Notes</h2>
            {loading ? (
              <p className="text-muted-foreground">Loading notes...</p>
            ) : notes.length === 0 ? (
              <p className="text-muted-foreground">No reading notes yet.</p>
            ) : (
              <div className="space-y-8">
                {notes.map((note) => (
                  <div 
                    key={note.id} 
                    className="p-6 rounded-lg border animate-fade-in space-y-4"
                  >
                    {note.image_url ? (
                      <img 
                        src={note.image_url} 
                        alt="Note image" 
                        className="w-full rounded-lg mb-4 object-cover max-h-96"
                      />
                    ) : (
                      <p className="text-foreground dark:text-foreground [background:linear-gradient(to_bottom,transparent_60%,#FEF7CD_40%)] dark:[background:linear-gradient(to_bottom,transparent_60%,#8B5CF6_40%)] inline">
                        {note.highlight}
                      </p>
                    )}
                    
                    <div className="flex items-center gap-2 text-sm text-muted-foreground">
                      {note.source_title && (
                        <span className="font-medium">{note.source_title}</span>
                      )}
                      {note.source_url && !note.source_url.startsWith('https://x.com') && (
                        <a 
                          href={note.source_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center gap-1 hover:text-primary transition-colors"
                        >
                          <ExternalLink size={14} />
                          <span>{getDomainFromUrl(note.source_url)}</span>
                        </a>
                      )}
                      {note.source_url?.startsWith('https://x.com') && (
                        <XProfileInfo url={note.source_url} />
                      )}
                      <span className="ml-auto">
                        {format(new Date(note.created_at), 'PP')}
                      </span>
                      {user && (
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleDelete(note.id)}
                          className="text-destructive hover:text-destructive/90"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      )}
                    </div>
                    
                    {note.comment && (
                      <p className="text-sm text-muted-foreground mt-2">
                        {note.comment}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            )}
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default NotesPage;