import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Article } from "@/integrations/supabase/types/article";
import { format } from "date-fns";
import { Trash2, Edit, Eye, FileText } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

type ArticleListProps = {
  articles: Article[];
  onEdit: (article: Article) => void;
  onDelete: (id: string) => void;
  showDraftsOnly?: boolean;
  minimal?: boolean;
};

export const ArticleList = ({ 
  articles, 
  onEdit, 
  onDelete, 
  showDraftsOnly = false,
  minimal = false 
}: ArticleListProps) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState<string | null>(null);

  const filteredArticles = showDraftsOnly
    ? articles.filter((article) => article.draft)
    : articles.filter((article) => !article.draft);

  const formatDate = (dateString: string) => {
    return format(new Date(dateString), "MMM d, yyyy");
  };

  const handleDeleteClick = (id: string) => {
    setArticleToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (articleToDelete) {
      onDelete(articleToDelete);
      setArticleToDelete(null);
    }
    setDeleteDialogOpen(false);
  };

  if (filteredArticles.length === 0) {
    return (
      <div className="text-center py-8 text-muted-foreground">
        {showDraftsOnly 
          ? "No draft articles found." 
          : "No published articles found."}
      </div>
    );
  }

  if (minimal) {
    return (
      <div className="space-y-4">
        {filteredArticles.map((article) => (
          <div 
            key={article.id}
            className="flex items-center justify-between border-b pb-3"
          >
            <div className="flex items-center gap-3">
              <div className={`p-2 rounded-md ${article.draft ? 'bg-[#FEF7CD]' : 'bg-[#F2FCE2]'}`}>
                <FileText className={`h-4 w-4 ${article.draft ? 'text-amber-600' : 'text-green-600'}`} />
              </div>
              <div>
                <h3 className="font-medium truncate max-w-[300px]">{article.title}</h3>
                <p className="text-xs text-muted-foreground">{formatDate(article.created_at)}</p>
              </div>
            </div>
            <div className="flex gap-2">
              {article.draft ? (
                <span className="text-xs bg-amber-100 text-amber-800 px-2 py-1 rounded-full">
                  Draft
                </span>
              ) : (
                <span className="text-xs bg-green-100 text-green-800 px-2 py-1 rounded-full">
                  Published
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="divide-y divide-border">
      {filteredArticles.map((article) => (
        <div key={article.id} className="grid grid-cols-[1fr_100px_100px] items-center gap-4 py-4">
          <div>
            <h3 className="font-medium">{article.title}</h3>
            <p className="text-sm text-muted-foreground">
              {formatDate(article.created_at)}
            </p>
          </div>
          <div className="flex justify-end">
            <Button
              variant="outline"
              size="sm"
              onClick={() => onEdit(article)}
              className="gap-2 border-[#E5DEFF] text-[#6E59A5] hover:bg-[#E5DEFF] hover:text-[#6E59A5]"
            >
              <Edit className="h-4 w-4" />
              Edit
            </Button>
          </div>
          <div className="flex justify-end">
            <Button
              variant="destructive"
              size="sm"
              onClick={() => handleDeleteClick(article.id)}
            >
              <Trash2 className="h-4 w-4 mr-2" />
              Delete
            </Button>
          </div>
        </div>
      ))}
      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete the
              article from our servers.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmDelete}>
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};
