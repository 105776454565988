import React from "react";
import { Button } from "@/components/ui/button";
import { Bold, Italic, Heading1, Heading2, List, ImagePlus } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";

interface EditorToolbarProps {
  onFormat: (format: string, value?: string) => void;
}

export const EditorToolbar = ({ onFormat }: EditorToolbarProps) => {
  const { toast } = useToast();

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      console.log('Uploading image:', file.name);
      
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${fileName}`;

      const { error: uploadError, data } = await supabase.storage
        .from('article-images')
        .upload(filePath, file);

      if (uploadError) {
        throw uploadError;
      }

      console.log('Image uploaded successfully:', data);

      const { data: { publicUrl } } = supabase.storage
        .from('article-images')
        .getPublicUrl(filePath);

      console.log('Public URL:', publicUrl);
      onFormat('image', publicUrl);

      toast({
        title: "Success",
        description: "Image uploaded successfully",
      });
    } catch (error) {
      console.error('Error uploading image:', error);
      toast({
        title: "Error",
        description: "Failed to upload image",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="flex gap-2 mb-4">
      <Button
        variant="outline"
        size="icon"
        onClick={() => onFormat('bold')}
        title="Bold"
      >
        <Bold className="h-4 w-4" />
      </Button>
      <Button
        variant="outline"
        size="icon"
        onClick={() => onFormat('italic')}
        title="Italic"
      >
        <Italic className="h-4 w-4" />
      </Button>
      <Button
        variant="outline"
        size="icon"
        onClick={() => onFormat('h1')}
        title="Heading 1"
      >
        <Heading1 className="h-4 w-4" />
      </Button>
      <Button
        variant="outline"
        size="icon"
        onClick={() => onFormat('h2')}
        title="Heading 2"
      >
        <Heading2 className="h-4 w-4" />
      </Button>
      <Button
        variant="outline"
        size="icon"
        onClick={() => onFormat('list')}
        title="Bullet List"
      >
        <List className="h-4 w-4" />
      </Button>
      <div className="relative">
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          title="Upload Image"
        />
        <Button
          variant="outline"
          size="icon"
          className="pointer-events-none"
          title="Upload Image"
        >
          <ImagePlus className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};