import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Calendar } from "@/components/ui/calendar";
import { supabase } from "@/integrations/supabase/client";
import { HabitForm } from "@/components/habits/HabitForm";
import { HabitList } from "@/components/habits/HabitList";

interface Habit {
  id: string;
  name: string;
  color: string;
}

const HabitAdmin = () => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date());
  const [habits, setHabits] = useState<Habit[]>([]);
  const [user, setUser] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        navigate('/login');
        return;
      }
      setUser(user);
      fetchHabits(user.id);
    };
    
    checkUser();
  }, [navigate]);

  const fetchHabits = async (userId: string) => {
    try {
      console.log('Fetching habits for user:', userId);
      const { data, error } = await supabase
        .from('habits')
        .select('*')
        .eq('user_id', userId);

      if (error) throw error;
      console.log('Fetched habits:', data);
      setHabits(data || []);
    } catch (error) {
      console.error('Error fetching habits:', error);
    }
  };

  if (!user) {
    return <div className="container py-8">Loading...</div>;
  }

  return (
    <div className="container py-8">
      <h1 className="text-2xl font-bold mb-6">Habit Tracker Admin</h1>
      
      <div className="grid gap-8 md:grid-cols-2">
        <div className="space-y-4">
          <h2 className="text-lg font-semibold">Select Date</h2>
          <Calendar
            mode="single"
            selected={selectedDate}
            onSelect={setSelectedDate}
            className="rounded-md border"
          />
        </div>
        
        <div className="space-y-6">
          <HabitForm 
            userId={user.id}
            onHabitCreated={() => fetchHabits(user.id)}
            habits={habits}
          />

          <HabitList 
            habits={habits}
            selectedDate={selectedDate}
            userId={user.id}
          />
        </div>
      </div>
    </div>
  );
};

export default HabitAdmin;