
import { FileText, List, BookOpen, Users, PenSquare, BookmarkPlus, Mail, Settings, LayoutDashboard } from "lucide-react";
import { useLocation, Link } from "react-router-dom";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";

const menuItems = [
  {
    title: "Dashboard",
    url: "/admin",
    icon: LayoutDashboard,
    description: "Overview"
  },
  {
    title: "Editor",
    url: "/admin/editor",
    icon: PenSquare,
    description: "Create new content"
  },
  {
    title: "Posts",
    url: "/admin/posts",
    icon: FileText,
    description: "Manage articles"
  },
  {
    title: "Habits",
    url: "/admin/habits",
    icon: List,
    description: "Track daily habits"
  },
  {
    title: "Notes",
    url: "/admin/notes",
    icon: BookmarkPlus,
    description: "Manage notes"
  },
  {
    title: "Subscribers",
    url: "/admin/subscribers",
    icon: Users,
    description: "Manage subscribers"
  },
  {
    title: "Emails",
    url: "/admin/emails",
    icon: Mail,
    description: "Send newsletters"
  },
  {
    title: "Site Settings",
    url: "/admin/site-settings",
    icon: Settings,
    description: "Configure site"
  },
];

export function AdminNav() {
  const location = useLocation();

  return (
    <Sidebar variant="sidebar" collapsible="icon">
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel className="text-[#7E69AB]">Admin Panel</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              {menuItems.map((item) => (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton 
                    asChild
                    tooltip={`${item.title} - ${item.description}`}
                    isActive={location.pathname === item.url || 
                              (item.url !== "/admin" && location.pathname.startsWith(item.url))}
                  >
                    <Link 
                      to={item.url}
                      className="flex items-center gap-3 text-[#403E43] hover:text-[#6E59A5]"
                    >
                      <item.icon className="h-5 w-5" />
                      <span>{item.title}</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
}
