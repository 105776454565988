import { Square } from "lucide-react";
import { Habit } from "./types";

interface HabitLegendProps {
  allHabits: Habit[];
  selectedHabit: string | null;
  onHabitToggle: (habitId: string) => void;
}

export const HabitLegend = ({ allHabits, selectedHabit, onHabitToggle }: HabitLegendProps) => {
  return (
    <div className="flex flex-wrap gap-4 mb-6">
      <label
        className="flex items-center gap-2 cursor-pointer"
        onClick={() => onHabitToggle(selectedHabit || '')} // Deselect current habit
      >
        <Square 
          className="w-4 h-4" 
          color="#216e39"
          fill={!selectedHabit ? "#216e39" : 'transparent'}
        />
        <span>All Habits</span>
      </label>
      {allHabits.map((habit) => (
        <label
          key={habit.id}
          className="flex items-center gap-2 cursor-pointer"
        >
          <Square 
            className="w-4 h-4" 
            color={habit.color}
            fill={selectedHabit === habit.id ? habit.color : 'transparent'}
            onClick={() => onHabitToggle(habit.id)}
          />
          <span>{habit.name}</span>
        </label>
      ))}
    </div>
  );
};